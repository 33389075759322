import React from 'react'
import FirstSection from './CaasHomeNew/Sections/FirstSection'
import SecondSection from './CaasHomeNew/Sections/SecondSection'
import ThirdSection from './CaasHomeNew/Sections/ThirdSection'
import FourSection from './CaasHomeNew/Sections/FourSection'
import Footer from './CaasHomeNew/Footer'
import NavBar from './CaasHomeNew/Sections/Navbar'

// Componente principal Content que recibe tres props: urlhero, setInfo y selectLang
const Content = ({
    urlhero,
    urlheader,
    setInfo,
    selectLang,
    isEnglish,
    hubspot,
}) => {
  return (
    <>
      {/* Renderiza la barra de navegación */}
      <NavBar 
        url={urlheader}
        hm={setInfo("LPV2_Header-HM")}
        qs={setInfo("LPV2_Header-QS")}
        cf={setInfo("LPV2_Header-CF")}
        ct={setInfo("LPV2_Header-CT")}
      />
      
      {/* Renderiza la primera sección pasando props específicas */}
      <FirstSection
        ia_tit={setInfo("LPV2_IA-Tit")}
        ia_neg={setInfo("LPV2_IA-Neg")}
        ia_desc={setInfo("LPV2_IA-Desc")}
        ia_btn={setInfo("LPV2_IA-Btn")}
        url={urlhero}
        isEnglish={isEnglish}
      />
      
      {/* Renderiza la segunda sección */}
      <SecondSection
        nos_tit={setInfo("LPV2_Nos-Tit")}
        nos_subt1={setInfo("LPV2_Nos-Subt1")}
        nos_subt2={setInfo("LPV2_Nos-Subt2")}
        nos_desc={setInfo("LPV2_Nos-Desc")}
      /> 
      
      {/* Renderiza la tercera sección */}
      <ThirdSection
        nos_flow_conv={setInfo("LPV2_Nos-FlujosConv")}
        nos_flow_conv_desc={setInfo("LPV2_Nos-FlujosConv-Desc")}
        nos_flow_cent={setInfo("LPV2_Nos-CentCliente")}
        nos_flow_cent_desc={setInfo("LPV2_Nos-CentCliente-Desc")}
        nos_flow_ia={setInfo("LPV2_Nos-IA")}
        nos_flow_ia_desc={setInfo("LPV2_Nos-IA-Desc")}
        nos_flow_per={setInfo("LPV2_Nos-PersEscalable")}
        nos_flow_per_desc={setInfo("LPV2_Nos-PersEscalable-Desc")}
        esc_tit={setInfo("LPV2_Esc-Tit")}
        esc_tit2={setInfo("LPV2_Esc-Tit2")}
        esc_sat={setInfo("LPV2_Esc-Sat")}
        esc_sat_desc={setInfo("LPV2_Esc-Sat-Desc")}
        esc_disp={setInfo("LPV2_Esc-Disp")}
        esc_disp_desc={setInfo("LPV2_Esc-Disp-Desc")}
        esc_usr={setInfo("LPV2_Esc-Usr")}
        esc_usr_desc={setInfo("LPV2_Esc-Usr-Desc")}
      />
      
      {/* Renderiza la cuarta sección */}
      <FourSection
        neg_tit={setInfo("LPV2_Neg-Tit")}
        neg_tit2={setInfo("LPV2_Neg-Tit2")}
        neg_desc={setInfo("LPV2_Neg-Desc")}
        neg_btn_red={setInfo("LPV2_Neg-Btn-Red")}
        neg_btn_mej={setInfo("LPV2_Neg-Btn-Mej")}
        neg_btn_aum={setInfo("LPV2_Neg-Btn-Aum")}
        card_tit={setInfo("LPV2_Footer-Card-Tit")}
        card_tit2={setInfo("LPV2_Footer-Card-Tit2")}
        card_btn={setInfo("LPV2_Footer-Card-Btn")}
        hubspot={hubspot}
      />
      
      {/* Renderiza el pie de página pasando múltiples props */}
      <Footer
       i2={setInfo("LPV2_Footer-Ac")}
       i3={setInfo("LPV2_Footer-Cont")}
       i4={setInfo("LPV2_Footer-Sop")}
       selectLang={selectLang}
       footer_dchos={setInfo("LPV2_Footer-Dchos")}
       footer_sig={setInfo("LPV2_Footer-Sig")}
      />
      
      {/* Comentario de código comentado para renderizar un componente de WhatsApp */}
      {/* <Whatsapp url={window.location.pathname} id="Whatsapp" /> */}
    </>
  )
}

// Exporta el componente Content como el valor por defecto
export default Content