/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-duplicates */
/* eslint-disable max-len */
/* eslint-disable import/named */
import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { ModalFull } from '../../components/common/common.styled';
import { TextMaxLine } from "../Routines/KnowScore.jsx";
import {
  ContentGeneric,
  Button,
  ButtonAlt,
} from "../../components/common/common.styled.js";
import Registro from './Registro';
import Login2 from './Login/index';
import { getQueryVariableHashtagDeeplink } from '../../Helpers';

export const ModalLogin = ({
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine = null,
  onLoginRoutine = null,
  onRegisterRoutine = null,
  isRecoveryUser = false,
  userData = {NameUser: '', EmailUser: '', PhoneUser: ''}
}) => {
  const [typeModal, setTypeModal] = useState("register"); // Existe login, register y recovery unicamente
  const [stateOfModal, setstateOfModal] = useState(true);

  const nextRoutine = () => {
    if (onLoginRoutine !== null) {
      setOrderRutine(onLoginRoutine);
    } else if (onRegisterRoutine !== null) {
      setOrderRutine(onRegisterRoutine);
    } else {
      setOrderRutine(nextOrderRoutine);
    }
    setstateOfModal(false);
  };

  useEffect(() => {
    const { idCont, idUser } = getQueryVariableHashtagDeeplink();
    if (typeof(idUser) !== 'undefined' && idUser !== '') {
      sessionStorage.setItem('idUser', idUser);
      sessionStorage.setItem('idCont', idCont);
    }
  }, []);

  useEffect(() => { // Verificamos que la sesión ya este iniciada
    if (sessionStorage.getItem('idUser') !== '' && sessionStorage.getItem('idUser') !== null && sessionStorage.getItem('idUser') !== 'undefined' /* && !isRecoveryUser */) {
      nextRoutine();
    }
  }, []);

  if (isRecoveryUser) return (
    <Modal
      open={stateOfModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        backgroundColor: 'rgba(7, 11, 17, 0.05)',
        position: 'fixed',
        top: '0px',
        overflowY: 'auto',
      }}
    >
      <ModalFull
        top="75px"
        margin="auto 7.5% 30px "
        padding="0 20px"
        width="85%"
        overflowY="scroll"
        bottom="auto"
        borderR="16px"
        className='d-flex flex-column'
      >
        <ContentGeneric
          position="relative"
          margin="40px 0 20px 0"
          className='col-12'
          padding="0px"
        >
          <TextMaxLine
            fontSize="14px"
            fontWeight="bold"
            textAlign="left"
            fontColor="#000"
            margin="0px auto 20px"
          >
            Confirma tus datos para continuar
          </TextMaxLine>
          <ContentGeneric
            position="relative"
            className='col-12'
            margin="auto"
            borderR="12px"
            padding="20px"
            background="#eaeaea"

          >
            <TextMaxLine
              fontSize="14px"
              fontColor="#252525"
              fontWeight="bold"
              textAlign="left"
            >
              Datos de contacto
            </TextMaxLine>
            <TextMaxLine
              fontSize="12px"
              fontColor="#252525"
              margin="10px auto 0 auto"
              textAlign="left"
            >
              {userData.NameUser}
            </TextMaxLine>
            <TextMaxLine
              fontSize="12px"
              fontColor="#252525"
              margin="10px auto 0 auto"
              textAlign="left"
            >
              {userData.EmailUser}
            </TextMaxLine>
            <TextMaxLine
              fontSize="12px"
              fontColor="#252525"
              margin="10px auto 0 auto"
              textAlign="left"
            >
              {userData.PhoneUser}
            </TextMaxLine>
          </ContentGeneric>
        </ContentGeneric>
        <Button mb="0" mt="0" className="roboto">
          Confirmar datos
        </Button>
        <ButtonAlt
          id="btnContrasena"
          type="button"
          className="d-block roboto"
          borderRadius="16px"
        >
          Editar datos
        </ButtonAlt>
      </ModalFull>
    </Modal>
  )

  return typeModal === "register" ? (
    <Modal
      open={stateOfModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        backgroundColor: 'rgba(7, 11, 17, 0.05)',
        position: 'fixed',
        bottom: '0px',
        top: '0px',
        overflowY: 'scroll',
      }}
    >
      <ModalFull top="75px" margin="0 7.5%" width="85%" overflowY="scroll">
        <Registro setTypeModal={setTypeModal} isModal={true} nextRoutine={nextRoutine} />
      </ModalFull>
    </Modal>
  ) : (
    <Modal
      open={stateOfModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        backgroundColor: 'rgba(7, 11, 17, 0.05)',
        position: 'fixed',
        top: '0px',
        overflowY: 'auto',
      }}
    >
      <ModalFull
        top="75px"
        margin="0 7.5% 30px "
        width="85%"
        overflowY="scroll"
        bottom="auto"
        borderR="16px"
        bottomxs="20px"
      >
        <Login2 isModal={true} setTypeModal={setTypeModal} nextRoutine={nextRoutine} />
      </ModalFull>
    </Modal>
  );
};

export const ModalMagicLink = ({ typeInfo, dato, closeModal, isBlocked }) => {
  const marginModalw = `${(window.screen.availWidth - 374) / 2}px`;
  const marginModalh = `${(window.screen.availHeight - 400) / 2}px`;

  const [tipeInfoPrint, settipeInfoPrint] = useState();
  useEffect(() => {
    if (typeInfo === "cel") {
      settipeInfoPrint("celular");
    } else {
      settipeInfoPrint("correo electónico");
    }
  }, [typeInfo]);

  return (
    <Modal
      open={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        backgroundColor: 'rgba(7, 11, 17, 0.05)',
        position: 'fixed',
        top: '0px',
        overflowY: 'auto',
      }}
    >
      <ModalFull
        top="75px"
        margin="0 7.5% 30px "
        padding="36px 21px"
        width="85%"
        widthMd="374px"
        marginMd={`${marginModalh} ${marginModalw}`}
        overflowY="scroll"
        bottom="auto"
        borderR="16px"
        bottomxs="20px"
        className='d-flex flex-column'
      >
        <ContentGeneric
          textAlign="end"
          fontSize='20px'
          margin='0px'
          padding='0px'
          className='col-12'
          style={{ cursor: 'pointer' }}
          onClick={() => closeModal()}
        >
          {/* <TextMaxLine
            fontSize="20px"
          >x</TextMaxLine> */}
        </ContentGeneric>
        <ContentGeneric dis="flex" flexDirection="column" gap="24px" position="relative" margin="0px !important" padding="0px !important" className='col-12'>
          <TextMaxLine
            fontSize="48px"
            weight="400px"
            textAlign="center"
          >
            ✨
          </TextMaxLine>
          {isBlocked ? (
            <TextMaxLine
              fontSize="12px"
              fontColor="#252525"
              weight="400px"
              textAlign="center"
            >
              Enviamos un enlace a tu {tipeInfoPrint} <span style={{ fontWeight: 'bold' }}>{dato} </span>  para que inicies sesión.
            </TextMaxLine>
          ) : (
            <TextMaxLine
              fontSize="12px"
              fontColor="#252525"
              weight="400px"
              textAlign="center"
            >
              En un par de minutos recibirás un enlace a tu {tipeInfoPrint} <span style={{ fontWeight: 'bold' }}>{dato} </span>  para que inicies sesión.
            </TextMaxLine>
          )}
        </ContentGeneric>
        <ContentGeneric className='col-12' padding="0px" height="auto">
          {isBlocked ? (
            <Button className="roboto" mb="0px">
              Solicitar nuevo enlace
            </Button>
          ) : (
            <Button className="roboto" onClick={() => closeModal()} mb="0px">
              Entendido
            </Button>
          )}
        </ContentGeneric>
      </ModalFull>
    </Modal>
  );
};

ModalMagicLink.propTypes = {
  typeInfo: PropTypes.string,
  dato: PropTypes.string,
  closeModal: PropTypes.func,
};
export default ModalLogin;
