import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import complete from '../../../assets/img/anacoach/corect.png';
import ShareCommonFeed from '../../../anaCoach/Training/ShareCommonFeed';
import ModalDatosBC from '../Modal/ModalDatosBC';
import ModalResetPassword from '../Modal/ModalResetPassword';
import ModalDatosUpdate from '../Modal/ModalDatosUpdate';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  ContainerSection,
  Subtitle,
  Buttons,
  TrData,
  ContainerBoxScrollBar,
  TdData,
  TdDataUTM,
  Lista,
  ListaItem,
  ContainerBox,
} from '../Styleds/Asesores.Styled.js';
import { makeStyles } from '@material-ui/core/styles';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { RecuperarContraseña } from '../Styleds/Asesores.Styled';

const UserData = ({
  basicInfo,
  dataBC,
  trainingByUser,
  openSessions,
  estatusTramites,
  consultarBC,
  tabTraining,
  setTabTraining,
  getUserInfo,
  ultimosDigitos,
  setUltimosDigitos,
  bancoActual,
  setBancoActual,
  typeasesor,
  ultimafecha,
  setFormBC,
  formbc,
}) => {
  const sinUltimosDigitos = '****';
  const sinBancoActual = 'No Seleccionado';
  const [modalDatosBC, setModalDatosBC] = useState(false);
  const [modaldatoscliente, setModalDatosCliente] = useState(false);
  const [modalrestpassword, setModalResetPassword] = useState(false);
  const [modalbc, setModalBc] = useState(false);
  const [viewbtn, setViewBtn] = useState(true);
  const [respuestaerror, setRespuestaError] = useState(false);
  const mensaje = '-009 Expediente sin cuentas para cálculo de BC Score';
  const Err01 = 'problemas_buro';
  const Err02 = 'no_autenticado';
  const [datosupdate, setDatosUpdate] = useState({});
  const asesor = 'admin_asesor';
  const UTM = url => {
    if (url !== null) {
      const win = window.open(url, '_blank');
      win.focus();
    }
  }

  const openModolDatos = dato => {
    setModalDatosCliente(true)
    if (dato === 'telefono') {
      setDatosUpdate({
        type: 'tel',
        name: 'telefono',
        name1: 'telefonoC',
        validacion: '',
        evento: 'Telefono',
        placeholder: 'Tu número celular a 10 dígitos',
        titulo: 'Teléfono',
        value: basicInfo.cellphone,
        idcont: basicInfo.idCont,
      });
    }
    if (dato === 'email')
      setDatosUpdate({
        type: 'email',
        name: 'email',
        name1: 'emailC',
        validacion: '',
        evento: 'Email',
        placeholder: 'Ingresa tu correo electónico',
        titulo: 'Correo',
        value: basicInfo.email,
        idcont: basicInfo.idCont,
      });

  }

  const ShowModalBc = () => {
    setViewBtn(false);
    setModalBc(true);
  }

  const onchangeForm = (e) => {
    setFormBC({
        ...formbc,
        [e.target.name]: e.target.value,
      });
    }

  const ConsultaScore = () => {
    if (formbc.Nombre !== '' && formbc.Rfc !== '' && formbc.Historial !== '') {
      consultarBC();  
      setModalBc(false);
    }
  }


  useEffect(() => {
    if (dataBC) {
      const Error = dataBC.RespuestaBC;
      const ver = Error.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      console.log(ver);
      if (ver === Err01 || ver === Err02) {
        setRespuestaError(true);
      } else {
        setRespuestaError(false);
      }
    }
  }, [dataBC]);

  return (
    <>
      <ContainerSection bckC="#ffff" dis="flex" fDir="row" wid="100%">
        <ContainerBoxScrollBar wid="175px" hei="300px" dis="flex" fDir="column">
         {/* <PerfectScrollbar>
          </PerfectScrollbar> */}
        </ContainerBoxScrollBar>

        <ContainerBox dis="flex" fDir="column" marR="150px">
          <Subtitle className="roboto">Datos del cliente</Subtitle>
          <Lista dis="flex" fDir="column" padd="0px">
            <ListaItem className="roboto">
              Nombre: <span>{basicInfo.fullName} </span>{' '}
            </ListaItem>
            <ListaItem className="roboto">
              ID User: <span>{basicInfo.idUser}</span>{' '}
            </ListaItem>
            <ListaItem className="roboto">
              Teléfono: <span>{basicInfo.cellphone}</span>{' '}
              {typeasesor === asesor ? <EditIcon
                color="primary"
                fontSize="small"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openModolDatos('telefono');
                }}
              /> : null}
            </ListaItem>
            <ListaItem className="roboto">
              Correo: <span>{basicInfo.email}</span>{' '}
              {typeasesor === asesor ? <EditIcon
                color="primary"
                fontSize="small"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  openModolDatos('email');
                }}
              /> : null}
            </ListaItem>
            <ListaItem className="roboto">
              Edad: <span> {basicInfo.age}</span>
            </ListaItem>
            <ListaItem className="roboto">
              <RecuperarContraseña
                onClick={() => {
                  setModalResetPassword(true);
                }}
              >
                Recuperar Contraseña
              </RecuperarContraseña> {' '}
            </ListaItem>
            { ultimafecha === '' ? (
              <ListaItem className="roboto">
               Sin Score
            </ListaItem>
            ) : (
              <ListaItem className="roboto">
               Ultima Consulta BC: <span> {ultimafecha} </span>{' '}
            </ListaItem>
            )}
            {
              !modalbc ? (
                null
              ) : (
                <>
                  <ListaItem className='roboto'>
                    <TextField
                      label="Nombre completo"
                      name="nombre"
                      value={formbc.nombre}
                      onChange={(e) => onchangeForm(e)} 
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </ListaItem>
                  <ListaItem className='roboto'>
                    <TextField
                      label="RFC"
                      name="rfc"
                      value={formbc.rfc}
                      onChange={(e) => onchangeForm(e)} 
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </ListaItem>
                    <FormControl variant="outlined">
                      <InputLabel >
                        ¿Tiene historial Crediticio?
                      </InputLabel>
                      <Select
                        label="¿Tiene historial Crediticio?"
                        name="historial"
                        value={formbc.historial}
                        onChange={(e) => onchangeForm(e)}
                        variant="outlined"
                      >
                        <MenuItem value="Si" name="Si" >
                          Si
                        </MenuItem>
                        <MenuItem value="No" name="No" >
                          No
                        </MenuItem>
                      </Select>
                    </FormControl>
                  <Buttons
                    bcK="#3F4D67"
                    marT="20px"
                    type="button"
                    onClick={ConsultaScore}
                  >
                    Consultar BC Score
                  </Buttons>
                </>
              )
            }
            {/* <Subtitle className="roboto">Datos BC</Subtitle>
            <Lista dis="flex" fDir="column" padd="0px">
              <ListaItem className="roboto">
                Últimos 4 dígitos:
                <span> {ultimosDigitos || sinUltimosDigitos} </span>
                <EditIcon
                  color="primary"
                  fontSize="small"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setModalDatosBC(true);
                  }}
                />
                {ultimosDigitos ? (
                  <CancelIcon
                    color="primary"
                    fontSize="small"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setUltimosDigitos('');
                    }}
                  />
                ) : null}
              </ListaItem>
              <ListaItem className="roboto">
                Banco Actual:<span> {bancoActual || sinBancoActual} </span>
                <EditIcon
                  color="primary"
                  fontSize="small"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setModalDatosBC(true);
                  }}
                />
                {bancoActual ? (
                  <CancelIcon
                    color="primary"
                    fontSize="small"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setBancoActual();
                    }}
                  />
                ) : null}
                  </ListaItem> */}
            {!dataBC ? (
              null
            ) : (
              <>
                <ListaItem className="roboto">
                  BC Score: <span>{dataBC.BCScore}</span>{' '}
                </ListaItem>
                <ListaItem className="roboto">
                  Comentario: {respuestaerror ? (<span>{mensaje}&nbsp;</span>) : (<span>{dataBC.RespComentario.split('-')[1]}&nbsp;</span>)}
                </ListaItem>
                <ListaItem className="roboto">
                  Tarjeta Activa: <span>{dataBC.activeCard}</span>{' '}
                </ListaItem>
                <ListaItem className="roboto">
                  Crédito Automotriz: <span>{dataBC.automotiveCredit}</span>{' '}
                </ListaItem>
                <ListaItem className="roboto">
                  Crédito Hipotecario: <span>{dataBC.mortgageCredit}</span>{' '}
                </ListaItem>
                <ListaItem className="roboto">
                  Tiene Historial:{' '}
                  <span>{!dataBC.tieneHistorial ? 'No' : 'Si'}</span>{' '}
                </ListaItem>
              </>
            )}
            { viewbtn ? (
              <Buttons
              bcK="#3F4D67"
              marT="20px"
              type="button"
              onClick={ShowModalBc}
            >
              Consultar BC Score
            </Buttons>
            ) : null}
          </Lista> 
        </ContainerBox>

        <ContainerBox dis="flex" fDir="column">
          <ContainerBox dis="flex">
            <ContainerBox dis="flex" fDir="column" marR="50px" wid="433px">
              <Subtitle className="roboto">
                Sesiones abiertas para el cliente
              </Subtitle>
              <div style={{ width: 'auto', height: '200px', overflow: 'auto' }}>
                <table className="table table-borderless table-sm">
                  <tbody>
                    {openSessions && openSessions.length > 0
                      ? openSessions.map((session, index) => (
                        <TrData key={index}>
                          <TdData className="roboto">{session.email}</TdData>
                          <TdData className="roboto">
                            {session.firstName}
                          </TdData>
                          <TdData className="roboto">
                            {moment(session.lastUpdate).format('DD/MMM/YYYY')}
                          </TdData>
                          <TdData className="roboto">{session.status}</TdData>
                        </TrData>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>
            </ContainerBox>

            <Grid item xs={12} md={6}>
              <Subtitle className="roboto">Entrenamientos concluidos</Subtitle>
              <table className="table table-borderless table-sm">
                <tbody>
                  {trainingByUser.map((training, index) => {
                    if (training.Achievment === 100) {
                      return (
                        <TrData key={index}>
                          <TdData className="roboto">{training.Name}</TdData>
                          <TdData className="roboto">
                            {moment(training.LastUpdated).format('DD/MMM/YYYY')}
                          </TdData>
                          <TdData className="roboto">
                            <img
                              alt="completeIcon"
                              style={{ width: '22px' }}
                              src={complete}
                            />
                          </TdData>
                          <TdData className="roboto">
                            <ShareCommonFeed
                              url={training.TrainingRedirectUrl}
                              listXCoach={true}
                              trainingName={training.Name}
                              mtop="0px"
                            ></ShareCommonFeed>
                          </TdData>
                        </TrData>
                      );
                    }
                  })}
                </tbody>
              </table>
            </Grid>
          </ContainerBox>

          <ContainerBox dis="flex" fDir="column" marR="50px" wid="418px">
            <Subtitle className="roboto" marL="5px">
              Estatus de trámites
            </Subtitle>
            <div style={{ width: 'auto', height: '200px', overflow: 'auto' }}>
              <table className="table table-borderless table-sm">
                <tbody>
                  {estatusTramites && estatusTramites.length > 0
                    ? estatusTramites.map((e, index) => (
                      <TrData key={index}>
                        <TdDataUTM className="roboto"
                          valido={e.ClickOut}
                          onClick={() => UTM(
                            e.ClickOut,
                          )}
                        >
                          {e.nombreOportunidad}
                        </TdDataUTM>
                        <TdData className="roboto">
                          {moment(e.fechaUpdate).format('DD/MMM/YYYY')}
                        </TdData>
                        <TdData className="roboto">
                          {e.estatus == 1 ? 'proceso' : e.estatus == 0 ? 'Sin solicitar' : ''}
                        </TdData>
                      </TrData>
                    ))
                    : null}
                </tbody>
              </table>
            </div>
          </ContainerBox>
        </ContainerBox>
      </ContainerSection>
      {modalDatosBC ? (
        <ModalDatosBC
          modalDatosBC={modalDatosBC}
          setModalDatosBC={setModalDatosBC}
          ultimosDigitos={ultimosDigitos}
          setUltimosDigitos={setUltimosDigitos}
          bancoActual={bancoActual}
          setBancoActual={setBancoActual}
        />
      ) : null}
      {modalrestpassword ? (
        <ModalResetPassword
          setModalResetPassword={setModalResetPassword}
          Email={basicInfo.email}
        />
      ) : null}
      {modaldatoscliente ? (
        <ModalDatosUpdate
          setModalDatosCliente={setModalDatosCliente}
          datosupdate={datosupdate}
          basicInfo={basicInfo}

        />
      ) : null}
    </>
  );
};

export default UserData;
