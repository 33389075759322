import React, { useRef, useEffect } from 'react';
import { SpanText } from './styles'

const AnimatedLetters = ({ text, isEnglish }) => {
  // Divide el texto en un array de letras
  const letters = text ? text.split('') : [];
  // Crea un array de referencias para cada letra
  const refs = useRef([]);

  useEffect(() => {
    // Función para animar las letras
    const animateLetters = () => {
      // Añade la clase 'in' a cada letra de manera escalonada
      refs.current.forEach((ref, index) => {
        setTimeout(() => {
          if (ref) {
            ref.classList.add('in');
          }
        }, index * 100); // Ajusta el tiempo según sea necesario
      });

      // Elimina la clase 'in' de todas las letras después de un tiempo
      setTimeout(() => {
        refs.current.forEach((ref) => {
          if (ref) {
            ref.classList.remove('in');
          }
        });
      }, letters.length * 100 + 2000); // Tiempo total de la animación + 3 segundos

      // Vuelve a llamar a la función para reiniciar la animación después de un tiempo
      setTimeout(animateLetters, letters.length * 100 + 5000); // Tiempo total de la animación + 6 segundos
    };

    // Inicia la animación
    animateLetters();
  }, [letters.length]);

  return (
    <SpanText
    style={{
      display: isEnglish ? 'inline' : 'inline-block',
      padding: isEnglish ? '10px' : 'auto',
    }}
    >
      {letters.map((letter, index) => (
        // Asigna una referencia a cada letra
        <span key={index} ref={el => refs.current[index] = el}>
          {letter}
        </span>
      ))}
    </SpanText>
  );
};

export default AnimatedLetters;