import styled, { keyframes } from "styled-components";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 130px;
  background-color: #ffffff;
  gap: 100px;
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    gap: 100px;
  }

  @media only screen and (max-width: 450px) {
    padding: 100px 24px 0 24px;
  }
`;

export const Row = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
  gap: 50px;
  width: 80%;
  justify-content: center;
  max-width: 1200px;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    /* gap: 0; */
    align-items: center;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
`;


export const CardMain =  styled.div`
  /* border: 1px solid #fff; */

  padding: 38px 50px 30px 50px;
  border-radius: 16px;
  position: relative;
  transition: box-shadow 0.3s ease;
  max-width: 370px;
  min-width: 370px;
  height: 100%;
  min-height: 315px;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  &:hover {
    /* border: 1px solid #6844ed; */
    box-shadow: 0px 10px 60px 0px rgba(104, 68, 237, 0.20);
  }

  @media only screen and (max-width: 450px) {
    width: 100%;
    min-width: auto;
    height: auto;
    min-height: auto;
    padding: 30px 20px 30px 20px;
  }
`

export const IconCardMain = styled.img`
  width: 42px;
  height: 42px;
`;

export const TitleCardMain = styled.h3`
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.72px;
  margin-top: 21px;
  margin-bottom: 8px;
`;


// Define la animación
const upDownAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); /* Ajusta este valor según sea necesario */
  }
`;

const rightLeftAnimation = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px); /* Ajusta este valor según sea necesario */
  }
`;

// Aplica la animación a ReactGray
export const ReactGray = styled.div`
  position: absolute;
  width: 150px;
  border-radius: 20px;
  height: 200px;
  top: -40px;
  left: 20px;
  background-color: #efefef;
  animation: ${upDownAnimation} 6s infinite; /* Ajusta la duración según sea necesario */
`;

// Aplica la animación a CircleGray
export const CircleGray = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  top: 40%;
  left: 50%;
  border-radius: 50%;
  background-color: #efefef;
  animation: ${rightLeftAnimation} 6s infinite; /* Ajusta la duración según sea necesario */
`;



export const InvertContent = styled.div`
  padding-top: 70px;
  /* padding-top: 42px; */
  /* padding-top: 22px; */

  min-width: 520px;
  @media only screen and (max-width: 768px) {
    min-width: unset;
    width: 100%;
  }
  /* padding-right: 61px; */
`;

export const Title = styled.h2`
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  letter-spacing: -1.44px;
  margin: 0;
  margin-bottom: 31px;
  @media only screen and (max-width: 768px) {
    text-align: center;
    word-wrap: break-word; /* Soporta navegadores más antiguos */
    overflow-wrap: break-word;
    hyphens: auto;
    }

    @media only screen and (max-width: 450px) {
    font-size: 32px;
    }
`;
export const SpanText = styled.span`
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  letter-spacing: -1.44px;
  font-style: italic;
  margin: 0;

  background: linear-gradient(317.36deg, #F64282 19.95%, #006DFF 76.03%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

padding-right: 10px;

@media only screen and (max-width: 450px) {
    font-size: 32px;
    }
`;

export const TextBottom = styled.p`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #696969;
  margin: 0;
  max-width: 400px;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const ContainerBackground = styled.div`
  padding-top: 0px;
  padding-bottom: 100px;
  position: relative;
  background: linear-gradient(180deg, #FFFFFF 0%, #E4DDFF 100%);
    display: flex;
  flex-direction: column;
  justify-content: center;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 800px;
    bottom: 0;
    left: 0;
    height: 200px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 450px) {
    background: none;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 280px;
  margin-top: 24px;
  @media only screen and (max-width: 1200px) {
    height: auto;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
`;

export const Card = styled.div`
  border: 1px solid #fff;
  padding: 38px 50px 30px 50px;
  border-radius: 16px;
  background: linear-gradient(317.36deg, #F64282 19.95%, #006DFF 76.03%);
  margin-bottom: 30px;
  position: relative;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  min-width: 400px;
  height: 100%;
  &:hover {
    border: 1px solid #6844ed; /* Cambia el borde a morado */
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 1200px) {
    min-height: 280px;

  }

  @media only screen and (max-width: 450px) {
    width: 100%;
    min-width: auto;
    height: auto;
    min-height: auto;
    max-width: 100%;
    padding: 30px 20px 30px 20px;
  }
`;

export const TitleCard = styled.h3`
  font-family: "Space Grotesk", sans-serif;
  color: #ffffff;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 38px;
  line-height: 36px;
  letter-spacing: -0.72px;
  margin-top: 21px;
  margin-bottom: 8px;
`;

export const TextCard = styled.p`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
`;

export const Place = styled.div`
  position: absolute;
  right: 40px;
  top: -30px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #6844ed;
  background-color: #fff;
  border-radius: 50%;
`;

export const ImagePlace = styled.img`
  width: 100%; /* Asegura que la imagen se ajuste al ancho del div */
  height: 100%; /* Ajusta la altura al alto del div */
  object-fit: cover; /* Mantiene la proporción y recorta la imagen si es necesario */
  display: block;
`;
