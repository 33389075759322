import React from 'react'
// Importing styled components from the styles file
import {
  Wrapper,
  Content,
  LeftContent,
  RightContent,
  Subtitle,
  SpanText,
  Text,
  ChipsWrapper,
  Chip,
  ImageChip,
  TextChip,
  SquareGray,
  CircleGray,
  PurpleWrapper,
  TextInPurple,
  SpanTextInPurple,
  ImageDiv,
  ButtonInPurple,
  PurpleContainer,
  Elipse1,
  Elipse2,
  Elipse3,
  Elipse4,
  Image,
  BottomBackground,
} from './styles'
// Importing images
import check from '../../Assets/Imgs/check-white.svg'
import mejoranps from '../../Assets/Imgs/mejoranps.png'
import ClickButton from '../../../../Helpers/HookHelpers'

// Functional component FourSection
const FourSection = ({
  neg_tit,
  neg_tit2,
  neg_desc,
  neg_btn_red,
  neg_btn_mej,
  neg_btn_aum,
  card_tit,
  card_tit2,
  card_btn,
  hubspot,
}) => {
  const { actions } = ClickButton();

  const handleClickCoru = (name) => {
    switch (name) {
      case 'Starttheconversation':
        actions({
          action: 'ClickButtonLPCaaS',
          variables: `Click_Button_iniciarconversacion`,
          collection: 'ClickButtonLPCaaS',
        });
        window.open(hubspot, '_blank');
        break;
      case 'BottonSeeallProducts':
        actions({
          action: 'ClickButtonLPCaaS',
          variables: `Click_BottonSeeallProducts`,
          collection: 'ClickButtonLPCaaS',
        });
        window.open(hubspot, '_blank');
        break;
      default:
        break;
    }
  };

  return (
    // Main wrapper for the section
    <Wrapper>
      <Content>
        {/* Left content section */}
        <LeftContent>
          {/* Subtitle with span text */}
          <Subtitle>{neg_tit}
            <SpanText> {neg_tit2}</SpanText>
          </Subtitle>
          {/* Description text */}
          <Text>{neg_desc}</Text>
          {/* Wrapper for chips */}
          <ChipsWrapper>
            {/* Individual chip with image and text */}
            <Chip>
              <ImageChip src={check} alt="" draggable={false} />
              <TextChip>{neg_btn_red}</TextChip>
            </Chip>
            <Chip>
              <ImageChip src={check} alt="" draggable={false} />
              <TextChip>{neg_btn_mej}</TextChip>
            </Chip>
            <Chip style={{ width: '100%'}}>
              <ImageChip src={check} alt="" draggable={false} />
              <TextChip>{neg_btn_aum}</TextChip>
            </Chip>
          </ChipsWrapper>
        </LeftContent>
        {/* Right content section */}
        <RightContent>
          {/* Image container */}
          <ImageDiv>
            <Image src={mejoranps} alt="" draggable={false} />        
          </ImageDiv>
        </RightContent>
      </Content>
      {/* Purple container section */}
      <PurpleContainer>
        <PurpleWrapper>
          <div>
            {/* Text inside purple wrapper */}
            <TextInPurple>{card_tit}</TextInPurple>
            <SpanTextInPurple>
              {card_tit2}
            </SpanTextInPurple>
          </div>
          {/* Button inside purple wrapper */}
          <ButtonInPurple
          onClick={() => handleClickCoru('Starttheconversation')}
          >
            {card_btn}
            </ButtonInPurple>
          {/* Decorative elements */}
          <Elipse1></Elipse1>
          <Elipse2></Elipse2>
          <Elipse3></Elipse3>
          <Elipse4></Elipse4>
        </PurpleWrapper>
        {/* Bottom background element */}
        <BottomBackground />
      </PurpleContainer>
    </Wrapper>
  )
}

// Exporting the component as default
export default FourSection