import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    background: linear-gradient(316.95deg, #E9F0FF 42.14%, #F8F8F8 61.86%);
    min-height: calc(100vh - 105px);
    max-height: calc(100vh - 105px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* gap: 60px; */
    overflow: hidden;

    @media only screen and (max-width: 950px) {
        padding: 50px 24px;
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 50px;
    }

    @media only screen and (max-width: 450px) {
        padding: 0px 24px;
        max-height: 100%;
        gap: 0;
    }
`

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 950px) {
        max-width: 70%;
        align-items: center;
    }

    @media only screen and (max-width: 450px) {
        max-width: 100%;
    }
`
export const TextContainer = styled.div``

export const Text = styled.p`
/* display: contents; */
font-family: "Space Grotesk", sans-serif;
font-style: normal;
font-weight: 700;
font-size: 65px;
line-height: 75px;
letter-spacing: -1.95px;
color: #000000;

width: 500px;

@media (max-width: 950px) {
    width: 100%;
    text-align: center;
}

@media (max-width: 450px) {
    font-size: 50px;
    line-height: 65px;
}
`

export const Description = styled.p`
    margin: 0;
    /* Optimiza tus procesos, mejora la atención al cliente y lleva tu negocio al siguiente nivel con soluciones personalizadas impulsadas por IA. */
    color: #707070;
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 39px;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 400;
    margin-top: 16px;
    @media (max-width: 950px) {
    width: 100%;
    text-align: center;
}

`

export const ButtonContainer = styled.div`
    position: relative;
    z-index: 1;
`

export const Button = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

position: relative;

z-index: 1;
height: 51px;

background: #F64282;
border-radius: 16px;
border: none;


font-family: "Space Grotesk", sans-serif;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120%;
text-align: center;
letter-spacing: -0.3px;

color: #FFFFFF;

padding: 20px 30px;
outline: none;
&:active {
    border: none;
}

&:focus {
    border: none !important;
    outline: none !important;
}

@media (max-width: 950px) {
    width: 100%;
}

`

export const RightContent = styled.div`
overflow: hidden;
    @media (max-width: 950px) {
        display: none;
    }
`

export const Image = styled.img`
    /* width: 10%; */
    /* width: 500px; */
    height: 706px;
    object-fit: cover;
    width: 700px;
    overflow: hidden;
    padding-left: 120px;

    /* background-color: coral; */
    @media (max-width: 1300px) {
       padding-left: 50px;
    }
    @media (max-width: 950px) {
        padding-left: 0;
        /* width: 100%;
        height: 100%; */
    }

    @media (max-width: 450px) {
        height: 400px;
        width: 100%;
    }
`