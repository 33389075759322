import styled, { keyframes, css } from 'styled-components';

const slideDown = keyframes`
  from {
    top: -105px;
  }
  to {
    top: 0;
  }
`;

const slideUp = keyframes`
  from {
    top: 0;
  }
  to {
    top: -105px;
  }
`;
export const Wrapper = styled.div`
    background-color: #181837;
    height: 105px;
    padding: 29px 52px 31px 70px;
    display: flex;
    align-items: 'center';
    width: 100%;
    position: relative;
    z-index: 999999999999;
    @media only screen and (max-width: 450px) {
    padding: 29px 20px 31px 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #181837;
  ${props => props.isFixed ? css`
    zIndex: 999999999999;
    position: fixed;
    height: 105px;
    left: 0;
    top: 0;
    padding: 29px 52px 31px 70px;
    animation: ${slideDown} 0.5s ease-in-out;
    @media only screen and (max-width: 450px) {
    padding: 29px 20px 31px 20px;
  }
  ` : css`
    animation: ${slideUp} 0.5s ease-in-out;
  `}

 
`;

export const LogoContainer = styled.div``;

export const Logo = styled.img`
    width: 130px;
    @media only screen and (max-width: 450px) {
      width: 90px;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    gap: 50px;

    @media only screen and (max-width: 945px) {
      display: none;
    }
`;

export const ItemsContainer = styled.div`
    display: flex;
    gap: 50px;
`;

export const Item = styled.div`
    font-family: 'Space Grotesk';
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    position: relative;
    cursor: pointer;    
    margin: 0;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #fff;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease-in-out;
    }

    &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
    }

    &:not(:hover)::after {
        transform: scaleX(0);
        transform-origin: right;
    }
`;

export const LenguageContainer = styled.div``;
export const MenuContainer = styled.div`
  display: none;
  
  @media only screen and (max-width: 945px) {
    display: block;
  }
`;

export const LenguageIcon = styled.img`
    cursor: pointer;
`;