import React from 'react';

function videoDemo() {
  const item = [
    {
      id: 1,
      video:
        'https://resources-new-coru.s3.us-east-2.amazonaws.com/images/video/Demo_Coru_V8_2021.mp4',
      formato: 'video/mp4',
    },
  ];
  return (
    <>
      {item.map((item) => (
        <div key={item.id}>
          <video width="100%" controls>
            <source src={item.video} type={item.formato} />
          </video>
        </div>
      ))}
    </>
  );
}
export default videoDemo;
