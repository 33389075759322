/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import TagManager from 'react-gtm-module';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import {
  TrainingText,
  TrainingDivSection,
  CardsBtn,
  ErrorDiv,
} from '../../assets/styles/Training.styled';
import CardTwo from '../../components/Home/Card2Animate';
import { RECOVERY_ROUTINE_BY_ORDER, GET_VALIDATION_EMAIL, EVEREST_VALIDATE_EMAIL } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';
import editIcon from '../../assets/img/anacoach/editar1.png';
import ClickButton from '../../Helpers/HookHelpers';
import SendNotificationEmail from '../../components/common/SendNotificationEmail';
import NotificationCoruRewards from '../../Helpers/NotificationCoruRewards';
import { INFOBIP_ACTIONS } from '../../Helpers/infobip'

const ContactInformation = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  mainDataRoutine = 0,
  trainingColor,
  deletePictureCoach = false,
  recoveryTimeout = false,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const { sendEmail } = SendNotificationEmail();
  const { sendNotification } = NotificationCoruRewards();
  const [iduser] = useState(sessionStorage.getItem('idUser') || '');
  const [idcont] = useState(sessionStorage.getItem('idCont') || '');
  const [recoveryDatosPrincipales, setRecoveryDatosPrincipales] = useState('');
  const [isData, setisData] = useState(
    sessionStorage.getItem('DataPerson') === 'true',
  );
  const [Rutine, setRutine] = useState({});
  const [getValidUserMailK] = useLazyQuery(GET_VALIDATION_EMAIL, {
    fetchPolicy: 'no-cache',
    onCompleted({ getValidUserMail }) {
      const resultMail = JSON.parse(getValidUserMail.response);
      // REGISTER NEW USER
      const datBasicRutine = JSON.parse(
        sessionStorage.getItem('Rutine_DatBasic'),
      );
      const datBasicQuestion = JSON.parse(
        sessionStorage.getItem('questions_DatBasic'),
      );

      const birthDatRutine = JSON.parse(
        sessionStorage.getItem('Rutine_BirthDat'),
      );
      const birthDatQuestion = JSON.parse(
        sessionStorage.getItem('questions_BirthDat'),
      );
      for (let i = 0; i < 3; i += 1) {
        if (i === 0) {
          createJsonInput(datBasicRutine, datBasicQuestion, '1');
        } else if (i === 1) {
          setTimeout(
            () => createJsonInput(birthDatRutine, birthDatQuestion, '2'),
            5000,
          );
        } else {
          setTimeout(() => createJsonInput(Rutine, questions, '3'), 10000);
        }
      }

      setQuestions([]);
      sessionStorage.setItem('idCont', resultMail.rel.idCont);
      sessionStorage.setItem('idUser', resultMail.rel.idUser);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);
        if (Object.keys(json).length === 0) {
          setRecoveryDatosPrincipales('inactiva');
        } else {
          const routines = json;
          let contResponse = 0;
          const stopRoutine = false;
          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionType } = arrayResponseRutine[i];
              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  contResponse++;
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
              break;
            }

            if (stopRoutine) {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
              break;
            } else {
              setRecoveryDatosPrincipales('activa');
              setQuestions([]);
            }
          }
        }
      } catch (error) {
        console.log('Error during mutation !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      iduser !== '' &&
      idcont !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryDatosPrincipales('inactiva');
    }
  }, []);
  const handleChangeRutine = (data) => setRutine(data);
  const [dataContact, setDataContact] = useState(
    sessionStorage.getItem('dataContact') || '',
  );
  const [alertaDatosP, setAlertaDP] = useState(false);
  const [alertaFecha, setAlertaFecha] = useState(false);
  const [clickBtn, setClickBtn] = useState(false);

  const [form, setForm] = useState({
    email: '',
    cel: '',
  });
  const [error] = useState({
    email: false,
    cel: false,
    emailvalid: form.email.length !== 0 ? 0 : -1,
    phonevalid: form.cel.length !== 0 ? 0 : -1,
  });

  const [texterror] = useState({
    email: '',
  });
  const ValidEmail = (id, valueEmail) => {
    let domain = valueEmail.split('@');
    domain = domain[1];
    const idmail = document.getElementById(id);

    if (
      valueEmail
      && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(valueEmail)
    ) {
      if (idmail) idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;

      error.emailvalid = 1;
    } else if (
      domain !== ''
      && (domain === 'gmail.co' || domain === 'hotmail.co')
    ) {
      if (idmail) idmail.className = 'textInput inputs-bottom invalidInput';
      texterror.email = 'Correo electrónico incorrecto';
      error.email = true;
      error.emailvalid = 1;
    } else {
      if (idmail) idmail.className = 'textInput inputs-bottom validInput';
      texterror.email = '';
      error.email = false;
      error.emailvalid = 0;
    }

    // eBtn();
  };
  const ValidPhone = (name, value, id) => {
    setForm({
      ...form,
      [name]: value.replace(/[^0-9]/i, ''),
    });
    const tel = document.getElementById(id);
    if (tel === null) {
      // console.log('null');
    } else if (value.length !== 10 || (name && !/^[0-9]+$/i.test(value))) {
      tel.className = 'textInput inputs-bottom invalidInput';
      texterror[name] = 'Por favor verifica tu número telefónico';
      error[name] = true;
      error[`${name}valid`] = 1;
    } else {
      tel.className = 'textInput inputs-bottom validInput';
      texterror[name] = '';
      error[`${name}valid`] = 0;
      error[name] = true;
    }
  };
  const ValidLetras = (nombre, valor, id) => {
    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ''),
    });
    const element = document.getElementById(id);
    if (element === null) {
      // console.log('null');
    } else if (valor.length > 2) {
      element.className = 'textInput inputs-bottom validInput';
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = '';
    } else {
      element.className = 'textInput inputs-bottom invalidInput';
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = 'Debe ingresar más de 3 caracteres';
    }
    // eBtn();
  };

  const handleChange = (e) => {
    if (e.target.name === 'email') {
      setForm({
        ...form,
        [e.target.name]: String(e.target.value).replace(' ', '').toLowerCase(),
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
    switch (e.target.name) {
      case 'email':
        ValidEmail(e.target.id, String(e.target.value).toLowerCase());
        break;
      case 'cel':
        ValidPhone(e.target.name, e.target.value, e.target.id);
        break;
      default:
        ValidLetras(e.target.name, e.target.value, e.target.id);
        break;
    }
  };

  useEffect(() => {
    if (recoveryDatosPrincipales === 'activa') {
      let cellPhone = sessionStorage.getItem('CellPhone') || '';      
      let email = sessionStorage.getItem('Email1') || '';
      cellPhone = email.indexOf('user_tem_') !== -1 ? '' : cellPhone;
      email = email.indexOf('user_tem_') !== -1 ? '' : email;
      setForm(
        Object.assign(form, {
          email,
          cel: cellPhone,
        }),
      );
      window.pe.setPerson({ phone: `52${cellPhone}` });
      sessionStorage.setItem('Email1', email);
      localStorage.setItem('Email1', email);
      sessionStorage.setItem('CellPhone', cellPhone);

      if (email !== '' && cellPhone !== '' && !recoveryTimeout) {
        setDataContact(true);
        sessionStorage.setItem('dataContact', true);
        setOrderRutine(nextOrderRoutine);
        setClickBtn(true);
      }
    }
  }, [recoveryDatosPrincipales]);

  useEffect(() => {
    const timer = setTimeout(() => {
      let cellPhone = sessionStorage.getItem('CellPhone') || '';
      let email = sessionStorage.getItem('Email1') || '';
      cellPhone = email.indexOf('user_tem_') !== -1 ? '' : cellPhone;
      email = email.indexOf('user_tem_') !== -1 ? '' : email;
      if (email !== null && email !== '') {
        ValidEmail('Email', sessionStorage.getItem('Email1'));
        const campo = document.getElementById('Email');
        if (campo) {
          campo.readOnly = true;
          campo.disabled = true;
        }
      }
      if (cellPhone !== null && cellPhone !== '') {
        ValidPhone(
          'cel',
          sessionStorage.getItem('CellPhone'),
          'Teléfono Celular',
        );
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };

  const handleChangeCatOption = () => {
  };

  const handleChangeDatosPersonales = (e) => {
    e.preventDefault();
    if (
      !error.email
      && form.email !== ''
      && form.cel !== ''
      && form.cel.length === 10
    ) {
      if (questions.length === 0) {
        questions.push(
          {
            id: 'Email1',
            question: 1,
            value: form.email,
          },
          {
            id: 'CellPhone',
            question: 2,
            value: form.cel,
          },
        );
      }
      sendEmail({ WO: 'FastTrack', trigger: 'email_FT3' });
      const birthDatRutineA = JSON.parse(
        sessionStorage.getItem('Rutine_BirthDat'),
      );
      const birthDatQuestionA = JSON.parse(
        sessionStorage.getItem('questions_BirthDat'),
      );

      actions({ action: 'validAffiliate', variables: form.email });
      if (sessionStorage.getItem('idTraining') === '35') {
        const clickEventCC = `DatosPersonales_${orderRutine}.${sessionStorage.getItem('idTraining')}`;
        actions({ action: 'ClickButtonCreditCards', variables: clickEventCC });
      }
      const click = `ContinuarDatosPersonales_${sessionStorage.getItem(
        'idTraining',
      )}`;
      actions({ action: 'ClickButtonCollection', variables: click });
      window.pe.setPerson({ phone: `52${form.cel}` });
      sendNotification();
      // if (sessionStorage.getItem('idTraining') === '35') {
      //   everestValidateEmail({
      //     variables: {
      //       email: form.email
      //     }
      //   })
      // }
      if (
        sessionStorage.getItem('idTraining') === '23'
        || (sessionStorage.getItem('idTraining') === '34'
          && birthDatRutineA
          && birthDatQuestionA)
        || sessionStorage.getItem('idTraining') === '26'
        || sessionStorage.getItem('idTraining') === '36'
        || sessionStorage.getItem('idTraining') === '35'
        || sessionStorage.getItem('idTraining') === '42'
      ) {
        sessionStorage.setItem('CellPhone', form.cel)
        sessionStorage.setItem('Email1', form.email)
        getValidUserMailK({
          variables: {
            email: form.email,
          },
        });
      } else {
        createJsonInput(Rutine, questions);
        setQuestions([]);
      }

      setDataContact('Envio Datos');

      setOrderRutine(nextOrderRoutine);
      const tagManagerArgs = {
        dataLayer: {
          event: 'PersonalInfo',
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      sessionStorage.setItem('dataContact', 'Envio Datos');
      setClickBtn(true);
      sessionStorage.setItem('DataPerson', true);
      INFOBIP_ACTIONS.CREDIT_CARD.STICKINESS();
    } else if (form.email !== '' && form.cel !== '' && form.cel.length >= 10) {
      setAlertaFecha(true);
      setAlertaDP(false);
    } else {
      setAlertaFecha(false);
      setAlertaDP(true);
    }
  };

  const [secciondataContact, setSecciondataContact] = useState(false);
  const handleChangeButton = () => {
    setOrderRutine(2);
    setSecciondataContact(false);
  };
  const anchop = window.screen.width;
  useEffect(() => {
    if (
      sessionStorage.getItem('Email1')
      && sessionStorage.getItem('CellPhone')
    ) {
      setOrderRutine(nextOrderRoutine);
      // setisData(true);
    } else {
      setisData(false);
    }
  }, []);

  const [reviewScroll, setReviewScroll] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (
        reviewScroll
        && (dataContact === '' || clickBtn === false)
        && orderRutine === currentRoutine
      ) {
        if (document.getElementById('ActiveContactInfo') !== null) {
          // gsap.to(window, { duration: 1, scrollTo: '#ActiveContactInfo' });
        } else {
          gsap.to(window, { duration: 1, scrollTo: '#ActiveContactInfo2' });
        }
        setReviewScroll(false);
      }
    }, 1500);
  }, []);

  if (
    recoveryDatosPrincipales === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    return (
      <>
        {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
          <>
            <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
              {/* <ImageCoachInicio /> */}
              <Col7 padding=' ' className='cardPrincipal1'>
                {/* <LoadingWo /> */}
              </Col7>
            </Container>
          </>
        ) : (
          <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
            <ImageCoachInicio />
            <Col7 padding=' ' className='cardPrincipal1'>
              <LoadingWo />
            </Col7>
          </Container>
        )}
      </>
    );
  }
  return !isData ? (
    <Fragment>
      <div id="ActiveContactInfo2"></div>
      <Container width="80%" mt="0" widthMobile="90%" mtMobile="0">
        <ImageCoachInicio
          classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
          topMobile="35px"
        />
        <Col7 maxwidth="100%" padding=" " className="cardPrincipal1">
          <TrainingDivSection
            hew="auto"
            className="topOne1"
            paddw="26px"
            mlw="0%"
            mtw="8%"
            wiw="100%"
            back="#F3F3F3"
            backw="#F3F3F3"
            bshaw="none"
            bsha=""
            br="4px"
            padding="20px"
            wi="100%"
            he="auto"
          >
            <TrainingText
              bot="0px"
              className="cabin"
              align="left"
              weight="600"
              size="20px"
              sizew="20px"
            >
              Datos de contacto
            </TrainingText>
            {dataContact === '' || clickBtn === false || orderRutine === 2 ? (
              <Fragment>
                <InputsOrder
                  handleChangeQuestionsSend={handleChangeQuestionsSend}
                  handleChangeRutine={handleChangeRutine}
                  orderRutine={currentRoutine}
                  currentTraining={currentTraining}
                  step="1"
                  handleChangeCatOption={handleChangeCatOption}
                  form={form}
                  handleChange={handleChange}
                  endQuestion={2}
                  inputError={error}
                  texterror={texterror}
                  namesDatContact={true}
                />
                <ErrorDiv className={alertaFecha === true ? '' : 'hidden'}>
                  Parece que no has ingresado tu correo electrónico
                </ErrorDiv>
                <ErrorDiv className={alertaDatosP === true ? '' : 'hidden'}>
                  Parece que te falto llenar algún campo
                </ErrorDiv>
                <CardsBtn
                  type="button"
                  min_w="100%"
                  min_web="100%"
                  mtopw="10px"
                  onClick={(e) => handleChangeDatosPersonales(e)}
                >
                  Continuar
                </CardsBtn>
                <br />
              </Fragment>
            ) : null}
          </TrainingDivSection>
        </Col7>
      </Container>
      <Fragment>
        <CardTwo
          classCard={
            dataContact !== ''
            && (secciondataContact || orderRutine !== currentRoutine)
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={`Email: ${form.email}`}
          text2={`Teléfono celular: ${form.cel}`}
          colorTraining={trainingColor}
        >
          <img
            alt="editIcon"
            className={true ? 'iconEditCommon' : 'hidden'}
            src={editIcon}
            onClick={() => handleChangeButton()}
          />
        </CardTwo>
      </Fragment>
    </Fragment>
  ) : null;
};

export default ContactInformation;
