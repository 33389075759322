/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useLazyQuery } from '@apollo/client';
import InputsOrder from '../../components/common/InputsOrder';
import CardOne from '../../components/Home/Card1Animate';
import CardTwo from '../../components/Home/Card2Animate';
import editIcon from '../../assets/img/anacoach/editar1.png';
import {
  TrainingQuestion,
  DivRespDP,
} from '../../assets/styles/Training.styled';
// recovery
import { RECOVERY_ROUTINE_BY_ORDER } from '../../queries';
import { arraySessionsRutines } from '../../Helpers';
import ImageCoachInicio from '../../components/Training/ImageCoachInicio';
import LoadingWo from '../../components/Training/loadingWo';
import { Container, Col7 } from '../../components/common/common.styled';

const PersonalInformation = ({
  Date,
  hora,
  orderRutine,
  setOrderRutine,
  currentTraining,
  createJsonInput,
  questions,
  setQuestions,
  currentRoutine,
  nextOrderRoutine,
  trainingColor,
  setactive4,
}) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const anchop = window.screen.width;
  const [iduser] = useState(sessionStorage.getItem('idUser') || '');
  const [idcont] = useState(sessionStorage.getItem('idCont') || '');
  const [recoveryRoutine, setRecoveryRecoveryRoutine] = useState('');
  const [lapiz, setLapiz] = useState([{ routine: '', active: '' }]);
  const [editGenero, setEditGenero] = useState('');
  const [editEstado, setEditEstado] = useState('');
  const [editEstudios, setEditEstudios] = useState('');
  const [editECivil, setEditECivil] = useState('');
  const [editDependientes, setEditDependientes] = useState('');
  const handleChangeIcon = (json) => {
    setEditGenero(false);
    setEditEstado(false);
    setEditEstudios(false);
    setEditECivil(false);
    setEditDependientes(false);
    setLapiz([json]);
  };
  // end recovery
  const [Rutine, setRutine] = useState({});
  const handleChangeRutine = (data) => setRutine(data);

  const [genero, setGenero] = useState(sessionStorage.getItem('Gender') || '');
  const [estadoNac, setEstadoNac] = useState(
    sessionStorage.getItem('BirthState') || '',
  );
  const [gradoEstudios, setGradoEstudios] = useState(
    sessionStorage.getItem('Ulltimo_grado_de_estudios') || '',
  );
  const [EstCivil, setEstCivil] = useState(
    sessionStorage.getItem('MaritalStatus') || '',
  );
  const [Dependientes, setDependientes] = useState(
    sessionStorage.getItem('Numero_de_dependientes') || '',
  );

  // modal banks

  const [form, setForm] = useState({});
  const [recoveryRoutineByOrder] = useLazyQuery(RECOVERY_ROUTINE_BY_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted({ recoveryRoutineByOrder }) {
      try {
        const json = JSON.parse(recoveryRoutineByOrder.response);

        if (Object.keys(json).length === 0) {
          setRecoveryRecoveryRoutine('inactiva');
        } else {
          const routines = json; // Object.values(json);
          console.log(routines);
          const contResponse = 0;

          const answers = {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
          };

          for (const idRoutine in routines) {
            const arrayResponseRutine = routines[idRoutine].responses;

            for (let i = 0; i < arrayResponseRutine.length; i++) {
              const idTypeQuestion = arrayResponseRutine[i].idType;
              const responseUser = arrayResponseRutine[i].responseValue;
              const { questionOrder } = arrayResponseRutine[i];
              const { questionType } = arrayResponseRutine[i];
              if (responseUser && responseUser !== '') {
                answers[questionOrder] = responseUser;
              }

              if (
                responseUser !== ''
                && responseUser !== 'null'
                && responseUser != null
              ) {
                if (questionType === 'Cat') {
                  const { detailQuestion } = routines[idRoutine].questions[i];
                  for (let x = 0; x < detailQuestion.length; x++) {
                    if (detailQuestion[x].FieldOption === responseUser) {
                      sessionStorage.setItem(
                        arraySessionsRutines[idTypeQuestion],
                        responseUser,
                      );
                    }
                  }
                } else {
                  sessionStorage.setItem(
                    arraySessionsRutines[idTypeQuestion],
                    responseUser,
                  );
                }
              }
            }
            if (answers[1] !== '') {
              setGenero(answers[1]);
              setEditGenero(true);
              gsap.to(window, {
                duration: 1,
                scrollTo: '#activeEstadoNacimiento',
              });
            }
            if (answers[2] !== '') {
              setEstadoNac(answers[2]);
              setEditEstado(true);
              gsap.to(window, {
                duration: 1,
                scrollTo: '#activeGradoEstudios',
              });
            }
            if (answers[3] !== '') {
              setGradoEstudios(answers[3]);
              setEditEstudios(true);
              gsap.to(window, { duration: 1, scrollTo: '#activeEstadoCivil' });
            }
            if (answers[4] !== '') {
              setEstCivil(answers[4]);
              setEditECivil(true);
              gsap.to(window, { duration: 1, scrollTo: '#activeDependientes' });
            }
            if (answers[5] !== '') {
              setDependientes(answers[5]);
              setEditDependientes(true);
              gsap.to(window, {
                duration: 1,
                scrollTo: '#activeRoutineDomicilio',
              });
              console.log('Vamos a la rutina >>> ', nextOrderRoutine);
              setOrderRutine(nextOrderRoutine);
            }

            if (
              arrayResponseRutine.length < routines[idRoutine].questions.length
            ) {
              console.log('rutina actual: ', orderRutine);
              console.log(
                'questions: ',
                routines[idRoutine].questions.length,
                'responses: ',
                arrayResponseRutine.length,
              );
              setRecoveryRecoveryRoutine('activa');
              setQuestions([]);
              break;
            }

            setRecoveryRecoveryRoutine('activa');
            setQuestions([]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  // Variables para preguntas y respuestas
  useEffect(() => {
    if (
      iduser !== '' &&
      idcont !== ''
    ) {
      recoveryRoutineByOrder({
        variables: {
          idTraining: String(currentTraining),
          orderRoutine: String(currentRoutine),
          idUser: String(sessionStorage.getItem('idUser')),
          idCont: String(sessionStorage.getItem('idCont')),
        },
      });
    } else {
      setRecoveryRecoveryRoutine('inactiva');
    }
  }, []);
  const handleChange = (e) => {
    console.log('handleChange', e.target.name, e.target.value);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeQuestionsSend = (numQuestion, name, value, type = '') => {
    console.log('handleChangeQuestionsSend', numQuestion, name, value);
    if (numQuestion === undefined) return;
    const elementExist = questions.filter((element) => element.id === name);
    if (elementExist.length !== 0) {
      for (const element of questions) {
        if (element.id === elementExist.id) {
          element.value = value;
        } else if (element.id === elementExist[0].id) {
          element.value = value;
        }
      }
    } else {
      questions.push({
        id: name,
        question: numQuestion,
        value,
      });
    }
    if (type === 'Cat') {
      console.log(questions);
      createJsonInput(Rutine, questions);
      setQuestions([]);
    }
  };
  const handleChangeCatOption = (val) => {
    console.log('handleChangeCatOption', val);
    switch (val.step) {
      case 0:
        setGenero(val.value);
        setEditGenero(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeEstadoNacimiento' });
        break;
      case 1:
        setEstadoNac(val.value);
        setEditEstado(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeGradoEstudios' });
        break;
      case 2:
        setGradoEstudios(val.value);
        setEditEstudios(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeEstadoCivil' });
        break;
      case 3:
        setEstCivil(val.value);
        setEditECivil(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeDependientes' });
        break;
      case 4:
        setDependientes(val.value);
        setEditDependientes(true);
        gsap.to(window, { duration: 1, scrollTo: '#activeRoutineDomicilio' });
        setOrderRutine(nextOrderRoutine);
        break;
      default:
        console.log('default break handleChangeCatOption case 5');
        break;
    }
  };
  useEffect(() => {
    if (recoveryRoutine === 'activa') {
      const dependientes = sessionStorage.getItem('Numero_de_dependientes') || '';
      setGenero(sessionStorage.getItem('Gender') || '');
      setEstadoNac(sessionStorage.getItem('BirthState') || '');
      setGradoEstudios(
        sessionStorage.getItem('Ulltimo_grado_de_estudios') || '',
      );
      setEstCivil(sessionStorage.getItem('MaritalStatus') || '');
      setDependientes(dependientes);
      if (dependientes !== '') {
        setOrderRutine(nextOrderRoutine);
      }
    }
  }, [recoveryRoutine]);
  useEffect(() => {
    if (lapiz[0].routine !== '') {
      setOrderRutine(lapiz[0].routine);
    }
  }, [lapiz]);

  const [lapizDependientes, setLapizDependientes] = useState(
    sessionStorage.getItem('ZipCode') || '',
  );
  useEffect(() => {
    if (lapizDependientes !== '') {
      setLapizDependientes('Quitar');
    }
  }, [lapizDependientes]);

  const [loaderWait, setLoaderWait] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderWait(false);
      if (orderRutine === currentRoutine && genero === '') {
        gsap.to(window, { duration: 1, scrollTo: '#activeGenero' });
      }
    }, 8000);
  }, []);
    
  if (
    recoveryRoutine === ''
    && sessionStorage.getItem('idCont') !== ''
    && sessionStorage.getItem('idCont') !== null
    && sessionStorage.getItem('idUser') !== ''
    && sessionStorage.getItem('idUser') !== null
  ) {
    console.log('mostrar loader');
    return (
      <>
        {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
          <>
            <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
              <ImageCoachInicio />
              <Col7 padding=' ' className='cardPrincipal1'>
                <LoadingWo />
              </Col7>
            </Container>
          </>
        ) : (
          <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
            <ImageCoachInicio />
            <Col7 padding=' ' className='cardPrincipal1'>
              <LoadingWo />
            </Col7>
          </Container>
        )}
      </>
    );
  }
  return (
    <div>
      {!loaderWait ? (
        <Fragment>
          <div id="activeGenero"></div>
          <CardOne
            classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
            classCard={
              orderRutine === currentRoutine || genero !== ''
                ? 'cardOne'
                : 'hidden'
            }
            classTop="topOne1"
            text="¿Cuál es tu género?"
            hora={hora}
          />

          <div className="d-none">
            <div form={form} handleChange={handleChange} />
          </div>

          <TrainingQuestion
            className={
              ((orderRutine === currentRoutine && genero === '')
                || (lapiz[0].routine === 5 && lapiz[0].active === 1))
              && editGenero !== true
                ? ''
                : 'hidden'
            }
          >
            {orderRutine === currentRoutine ? (
              <InputsOrder
                handleChangeRutine={handleChangeRutine}
                orderRutine={orderRutine}
                step="0-0"
                handleChangeQuestionsSend={handleChangeQuestionsSend}
                handleChangeCatOption={handleChangeCatOption}
                form={form}
                handleChange={handleChange}
                currentTraining={currentTraining}
                activeStep={true}
              />
            ) : null}
          </TrainingQuestion>
        </Fragment>
      ) : (
        <>
        {parseInt(sessionStorage.getItem("idTraining")) === 46 ? (
          <>
            <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
              <ImageCoachInicio />
              <Col7 padding=' ' className='cardPrincipal1'>
                <LoadingWo />
              </Col7>
            </Container>
          </>
        ) : (
          <Container width='80%' mt='1rem' widthMobile='90%' mtMobile='1rem'>
            <ImageCoachInicio />
            <Col7 padding=' ' className='cardPrincipal1'>
              <LoadingWo />
            </Col7>
          </Container>
        )}
      </>
      )}
      <div id="activeEstadoNacimiento"></div>
      <CardTwo
        classCard={
          genero !== ''
          && (editGenero === true
            || !(lapiz[0].routine === 5 && lapiz[0].active === 1))
            ? 'cardTwoResp'
            : 'hidden'
        }
        classTop="topTwo1"
        imgMan="hidden"
        text={genero}
        colorTraining={trainingColor}
      >
        <img
          alt="editIcon"
          className={
            genero !== '' && estadoNac === '' ? 'iconEditCommon' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 1 })}
        />
      </CardTwo>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          genero !== ''
          && (editGenero === true
            || !(lapiz[0].routine === 5 && lapiz[0].active === 1))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿En qué estado naciste?"
        hora={hora}
      />
      <TrainingQuestion
        height="320px"
        flow="scroll"
        className={
          ((genero !== '' && estadoNac === '')
            || (lapiz[0].routine === 5 && lapiz[0].active === 2))
          && (!(lapiz[0].routine === 5 && lapiz[0].active === 1)
            || editGenero === true)
          && editEstado !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="1-1"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <DivRespDP>
        <div id="activeGradoEstudios"></div>
        <img
          alt="editIcon"
          className={
            estadoNac !== '' && gradoEstudios === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 2 })}
        />
        <CardTwo
          classCard={
            estadoNac !== ''
            && (editEstado === true
              || !(lapiz[0].routine === 5 && lapiz[0].active === 2))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={estadoNac}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          estadoNac !== ''
          && (editEstado === true
            || !(lapiz[0].routine === 5 && lapiz[0].active === 2))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuál es tu grado de estudios?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((estadoNac !== '' && gradoEstudios === '')
            || (lapiz[0].routine === 5 && lapiz[0].active === 3))
          && (!(lapiz[0].routine === 5 && lapiz[0].active === 2)
            || editEstado === true)
          && editEstudios !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="2-2"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeEstadoCivil"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            gradoEstudios !== '' && EstCivil === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 3 })}
        />
        <CardTwo
          classCard={
            gradoEstudios !== ''
            && (editEstudios === true
              || !(lapiz[0].routine === 5 && lapiz[0].active === 3))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={gradoEstudios}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          gradoEstudios !== ''
          && (editEstudios === true
            || !(lapiz[0].routine === 5 && lapiz[0].active === 3))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuál es tu estado civil?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((gradoEstudios !== '' && EstCivil === '')
            || (lapiz[0].routine === 5 && lapiz[0].active === 4))
          && (!(lapiz[0].routine === 5 && lapiz[0].active === 3)
            || editEstudios === true)
          && editECivil !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="3-3"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            EstCivil !== '' && Dependientes === '' ? 'iconEdit1' : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 4 })}
        />
        <div id="activeDependientes"></div>
        <CardTwo
          classCard={
            EstCivil !== ''
            && (editECivil === true
              || !(lapiz[0].routine === 5 && lapiz[0].active === 4))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={EstCivil}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <div id="economicDependents"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          EstCivil !== ''
          && (editECivil === true
            || !(lapiz[0].routine === 5 && lapiz[0].active === 4))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        text="¿Cuántas personas dependen económicamente de ti?"
        hora={hora}
      />
      <TrainingQuestion
        className={
          ((EstCivil !== '' && Dependientes === '')
            || (lapiz[0].routine === 5 && lapiz[0].active === 5))
          && (!(lapiz[0].routine === 5 && lapiz[0].active === 4)
            || editECivil === true)
          && editDependientes !== true
            ? ''
            : 'hidden'
        }
      >
        {orderRutine === currentRoutine ? (
          <InputsOrder
            handleChangeRutine={handleChangeRutine}
            orderRutine={orderRutine}
            step="4-4"
            handleChangeQuestionsSend={handleChangeQuestionsSend}
            handleChangeCatOption={handleChangeCatOption}
            form={form}
            handleChange={handleChange}
            currentTraining={currentTraining}
            activeStep={true}
          />
        ) : null}
      </TrainingQuestion>
      <div id="activeRoutineDomicilio"></div>
      <DivRespDP>
        <img
          alt="editIcon"
          className={
            Dependientes !== '' && lapizDependientes === ''
              ? 'iconEdit1'
              : 'hidden'
          }
          src={editIcon}
          onClick={() => handleChangeIcon({ routine: 5, active: 5 })}
        />
        <CardTwo
          classCard={
            Dependientes !== ''
            && (editDependientes === true
              || !(lapiz[0].routine === 5 && lapiz[0].active === 5))
              ? 'cardTwoResp'
              : 'hidden'
          }
          classTop="topTwo1"
          imgMan="hidden"
          text={Dependientes}
          colorTraining={trainingColor}
        ></CardTwo>
      </DivRespDP>
      <div id="irDependientes"></div>
      <CardOne
        classAna={anchop <= 768 ? 'falseAna' : 'trueAna'}
        classCard={
          Dependientes !== ''
          && (editDependientes === true
            || !(lapiz[0].routine === 5 && lapiz[0].active === 5))
            ? 'cardOne'
            : 'hidden'
        }
        classTop="topOne1"
        textbold="¡Vas muy bien! 👏"
        text="Ahora ingresa tu domicilio para continuar."
        hora={hora}
      />
      <div className="d-none">
        <div form={form} handleChange={handleChange} />
      </div>
    </div>
  );
};

export default PersonalInformation;
