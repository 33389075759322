import React from 'react'
import {
  Container,
  TextContainer,
  Text,
  Description,
  ButtonContainer,
  Button,
  LeftContent,
  RightContent,
  Image,
} from './styles'
import A1 from '../../Assets/Imgs/a1.svg'
import ClickButton from '../../../../Helpers/HookHelpers'
import AnimatedLetters from '../../AnimatedLetters'

// Componente FirstSection
const FirstSection = ({
  ia_tit,
  ia_neg,
  ia_desc,
  ia_btn,
  url,
  isEnglish,
}) => {
  // Hook personalizado para manejar clics en botones
  const { actions } = ClickButton();

  // Función para manejar los clics en los botones
  const handleClickCoru = (name) => {
    switch (name) {
      case "solutions":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_solutions_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "products":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_products_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "coru":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_coru_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "successstories":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_casosexito_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "traductor":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_traductor_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "getstarted":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_demo_header_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonReadytoStartHero":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonDemo_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        // Abrir una nueva pestaña con la URL proporcionada
        window.open(url, '_blank');
        break;
      case "BottonLetsGetToWork":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_btndemoProductos_no.wo`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonStartnow":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonStartnow`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      case "BottonSeeallProducts":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonSeeallProducts`,
          collection: "ClickButtonLPCaaS",
        });
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <LeftContent>
        <TextContainer>
          <Text>
          {ia_tit} 
          <AnimatedLetters text={ia_neg} isEnglish={isEnglish} />
          </Text>
        </TextContainer>
        <Description>
        {ia_desc}
        </Description>
        <ButtonContainer>
          <Button
            onClick={() => handleClickCoru("BottonReadytoStartHero")}
          >{ia_btn}</Button>
        </ButtonContainer>
      </LeftContent>
      <RightContent>
        <Image src={A1} alt="A1" draggable={false} />
      </RightContent>
    </Container>
  )
}

export default FirstSection