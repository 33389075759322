import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { MenuOptions, Menu2 } from '../Assets/Styles/CaasNew.styles';
import Selected from "../Assets/Imgs/Selected.svg";
import NotSelected from "../Assets/Imgs/NotSelected.svg";

const LanguageMenu = ({
  top,
  left,
  bottom,
  right,
}) => {
  const [selectLang, setSelectLang] = useState(2);
  const urlh = window.location.href;
  const demo = urlh.includes("/agendademo");
  { /* -------------rutas de landing page ------------- */ }
  const landingEs = "/caas/es/";
  const landingEn = "/caas/en/";
  const landingPt = "/caas/pt/";

  { /* -------------rutas de sección Demo------------- */ }
  const demoEs = "/caas/agendademo";
  const demoEn = "/caas/agendademo";
  const demoPt = "/caas/agendademo";

  useEffect(() => {
    if (urlh.includes("/en/")) {
      setSelectLang(2);
    } else if (urlh.includes("/pt/")) {
      setSelectLang(3);
    } else {
      setSelectLang(1);
    }
  }, [urlh]);
  return (
    <>
              <Menu2
                style={{
                  top: top || '70px',
                  left: left,
                  bottom: bottom,
                  right: right || '60px',
                }}
              >
                <Link to={demo ? demoEn : landingEn} style={{ textDecoration: "none" }}>
                  <MenuOptions
                    jcontent="flex-start"
                    style={{ cursor: "pointer" }}
                  >
                    {selectLang === 2 ? (
                      <img alt="" src={Selected} />
                    ) : (
                      <img alt="" src={NotSelected} />
                    )}
                    English
                  </MenuOptions>
                </Link>
                <Link to={demo ? demoEs : landingEs} style={{ textDecoration: "none" }}>
                  <MenuOptions
                    jcontent="flex-start"
                    style={{ cursor: "pointer" }}
                  >
                    {selectLang === 1 ? (
                      <img alt="" src={Selected} />
                    ) : (
                      <img alt="" src={NotSelected} />
                    )}
                    Spanish
                  </MenuOptions>
                </Link>
                <Link to={demo ? demoPt : landingPt} style={{ textDecoration: "none" }}>
                  <MenuOptions
                    jcontent="flex-start"
                    style={{ cursor: "pointer" }}
                  >
                    {selectLang === 3 ? (
                      <img alt="" src={Selected} />
                    ) : (
                      <img alt="" src={NotSelected} />
                    )}
                    Portuguese
                  </MenuOptions>
                </Link>
              </Menu2>
    </>
  );
};

export default LanguageMenu;
