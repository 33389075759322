import styled from "styled-components";

export const SecondSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 55px;
    background-color: #ffffff;

    @media only screen and (max-width: 768px) {
        gap: 40px;
    }

    @media only screen and (max-width: 450px) {
        padding: 0 24px;
    }
`

export const TitleContainer = styled.div`
    margin-top: 130px;

    @media only screen and (max-width: 450px) {
      margin-top: 100px;
    }
`
    
export const Title = styled.div`
    color: #030014;
    font-size: 48px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: -1.44px;
    font-family: "Space Grotesk", sans-serif;
    text-rendering: geometricPrecision;
    text-align: center;
    @media (max-width: 834px) {
        margin-top: 100px;
        margin-bottom: 00px;
        font-size: 32px;
        width: 100%;
  }
    @media (max-width: 450px) {
        margin-top: 24px;
        margin-bottom: 24px;
        line-height: 40px;
        display: grid;
  }
`;

export const SpanText = styled.span`

font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: 75px;
  letter-spacing: -1.95px;
  width: 100%;
  font-style: italic;  
  text-rendering: geometricPrecision;

  display: inline;

    padding-right: 9px;
  margin-right: -8px;
  color: transparent;

  background: linear-gradient(317.36deg, #F64282 19.95%, #006DFF 76.03%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

@media only screen and (max-width: 450px) {
  padding-right: 0px;
  margin-right: 0px;
  line-height: 40px;

}
  `

  export const MiniTitle = styled.p`
  font-family: "Space Grotesk", sans-serif;

  color: #000000;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.66px;
    text-align: center;
    margin: 0px;
  `