import styled from "styled-components";

export const ImgGeneric = styled.img`
  width: ${(props) => props.width || ''};
  height: ${(props) => props.height || ''};
  display: ${(props) => props.display || ''};
  position: ${(props) => props.position || ''};
  z-index: ${(props) => props.zIndex || ''};
  margin: ${(props) => props.margin || 'auto'};
  align-self:${(props) => props.alignSelf || ''};
  @media (max-width: 768px) {
    width: ${(props) => props.widthMds || ''};
    height: ${(props) => props.heightMds || ''};
    right: ${(props) => props.rightMds || ''};
    margin-top: ${(props) => props.marginTopMds || ''};
    position: ${(props) => props.positionMds || ''};
  }
`;
export const TextGeneric = styled.p`
display: flex;
justify-content: ${(props) => props.justifyContent || 'center'};
align-items: center;
text-align: ${(props) => props.textAlign || 'center'};
margin: ${(props) => props.margin || 'auto'};
padding: ${(props) => props.padding || '8px'};
gap: 8px;
font-size: ${(props) => props.fontSize || '12px'};
line-height: ${(props) => props.line || '130%'};
letter-spacing: 1px;
font-weight: ${(props) => props.fontWeight || '400'};
color: ${(props) => props.color || 'rgba(89, 89, 89, 0.8)'};
width:  ${(props) => props.width || '100%'};
height: auto;
`;
export const TextD = styled.div`
display: flex;
flex-direction: row;
/* display: grid;
grid-template-columns: ${(props) => props.gridTemplateColumns || 'repeat(2, 2fr)'}; */
justify-content: center;
align-items: center;
text-align: center;
margin: ${(props) => props.margin || 'auto'};
padding: ${(props) => props.padding || '8px'};
gap: 8px;
font-size: ${(props) => props.fontSize || '12px'};
font-weight: ${(props) => props.fontWeight || '400'};
color: #464646;
width:  ${(props) => props.width || '100%'};
height: auto;

box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.08);
border-radius: 4px;

background: #F9F9F9;

order: 1;
flex-grow: 0;
`;

export const Nav = styled.nav`
  display: flex;
  right: 20px !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  z-index: 3;
  @media (max-width: 834px) {
    padding: 24px;
  }
`;

export const GenContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  right: 0px !important;
  align-items: center;
  background-color: #f9f9f9;
  width: 100%;
  position: relative;
`;
export const GenContainerG = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  background-color: #f9f9f9;
  width: ${(props) => props.width || '100%'};
  position: relative;
`;

export const ContainerPage1 = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  position: relative;
  background-color: #FFE4EE;
  min-height: calc(100dvh - 80px);
  justify-content: center;
  align-items: center;
  @media (max-width: 833px) {
    padding-top: 0px;
    min-height: unset;
    padding: 40px 16px;
  }
`;

export const ContainerPage2 = styled.div`
  display: flex;
  width: 100%;
  // max-width: 1440px;
  flex-direction: column;
  position: ${(props) => props.position || ""};
  height: auto;
  padding: ${(props) => props.padding};
  background-color: #FEFEFE;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 834px) {
    padding: ${(props) => props.mobilPadding};
  }
  @media (max-width: 375px) {
    padding: ${(props) => props.tabletPadding};
  }
`;

export const ShapeBackground = styled.img`
  width: 100%;
  position: absolute;
  z-index: 1;
`;

export const LogoNav = styled.img`
  width: 100%;
  height: 100%;
  max-height: 44px;
  max-width: 115px;
`;

export const NavInsideContain = styled.div`
  display: flex;
  height: auto;
  width: auto;
  align-items: center;
  gap: 44px;
`;

export const OptionsContainer = styled.div`
  width: auto;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #24193f;
  gap: 48px;
`;

export const Contact = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #24193f;
  cursor: pointer;
`;

export const ContactNumber = styled.span`
  color: #403a50;
`;

export const StartedButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  width: auto;
  height: 48px;
  background: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #f64282;
  border: 1px solid #f64282;
  border-radius: 16px;
  &:hover {
    background: #FFE4EE;
    color: #f64282;
  }
`;

export const ContainerGeneral = styled.div`
  flex-direction: ${(props) => props.direction || "column"};
  width: 100%;
  height: ${(props) => props.height || "100%"};
  padding: ${(props) => props.mpad || "0 24px"};
  align-items: ${(props) => props.align || ""};
  justify-content: ${(props) => props.justify || ""};
  z-index: 2;
  position: relative;
  display: ${(props) => props.display || "flex"};
  background-color:${(props) => props.background || ""};
  border-top-style: ${(props) => props.borderTop || ""};
  border-width: ${(props) => props.borderWidth || ""}; 
  border-color:${(props) => props.borderColor || ""};
  display: ${(props) => props.display || ""};
  @media (min-width: 1280px) {
    padding: ${(props) => props.padding || "0 80px"};
    gap: ${(props) => props.mgap || ""};
  }
  @media (max-width: 1279px) {
    flex-direction: ${(props) => props.tdirection || ""};
    align-items: ${(props) => props.talign || ""};
    justify-content: ${(props) => props.tjustify || ""};
    gap: ${(props) => props.gap || ""};
    margin: ${(props) => props.margin || ""};
  }
  @media (max-width: 768px) {
    margin: ${(props) => props.marginSm || ""};
  }
  @media (max-width: 900px) {
    margin: ${(props) => props.marginTablet || ""};
  }
  @media (max-width: 834px) {
    margin: ${(props) => props.marginTablet || ""};
    padding: ${(props) => props.tabletPadding};
  }
  @media (max-width: 375px) {
    padding: ${(props) => props.mobilPadding};
  }
`;
export const Content1 = styled.div`
  width: 100%;
  position: ${(props) => props.position || ""};
  top: ${(props) => props.mtop || "160px"};
  margin-top: ${(props) => props.mgtop || ""};
  max-width: ${(props) => (props.limited ? "643px" : "")};
  display:  ${(props) => props.display || ""};
  /* @media (min-width: 1280px) {
    max-width:  ${(props) => props.widthD || "80%"};
  } */
`;

export const TextTitle1 = styled.p`
  margin-bottom: 0; //quita un margen que les pone a los textos por default
  font-family: cabin;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: ${(props) => props.weight || "700"};
  font-size: ${(props) => props.size || "52px"};
  color: #464646;
  margin: ${(props) => props.margin || ""};
  line-height: 120%;
  @media (max-width: 834px) {
    font-size: ${(props) => props.msize || "32px"};
    margin: ${(props) => props.tabletMargin};
    width: auto;
    text-align:  ${(props) => props.textAlign};
  }
  @media (max-width: 768px) {
    margin: ${(props) => props.mobilMargin || '28px 0'};
    font-size: ${(props) => props.msize || "32px"};
    font-size: ${(props) => props.mobilSize};
  }
  @media (min-width:  835px) {
    width: ${({ width }) => width || ""};
  }
`;

export const ContentSpan = styled.span`
  color: #000;
  background: #fff;
  mix-blend-mode: multiply;
  position: relative;
  background: linear-gradient(317.36deg, #f64282 19.95%, #006dff 76.03%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: Cabin;
  font-style: normal;
  font-weight: ${(props) => props.weight || "700"};
  font-size: ${(props) => props.size || "52px"};
  width: ${({ width }) => width || ""};
  text-transform: ${(props) => props.transform || "none"};
  line-height: 110%;  
  @media (max-width: 768px) {
    font-size: ${(props) => props.msize || "32px"};
    text-align: ${(props) => props.textAlign};
  }
`;

export const SubContent = styled.p`
  font-family: "Inter";
  font-style: normal;
  text-align: ${(props) => props.textAlign || "left"};
  font-weight: ${(props) => props.weight || "900"};
  font-size: ${(props) => props.size || "20px"};
  color: ${(props) => props.color || "#595959"};
  margin: ${(props) => props.margin || "auto"};
  line-height: ${(props) => props.line || ""};
  width: ${(props) => props.width || ""};
  cursor:${(props) => props.cursor || ""};
  display:${(props) => props.display || ""};
  height:${(props) => props.height || ""};
  align-items:${(props) => props.alignItem || ""};
  flex-direction:${(props) => props.flexDir || ""};
  @media (max-width: 834px) {
    font-size: ${(props) => props.msize || "32px"};
    width:auto;
  }
  @media (max-width: 768px) {
    font-size: ${(props) => props.msize || "16px"};
    width: ${(props) => props.widthM || "auto"};
  }
`;

export const ContentCardP = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 13px;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "350px"};
  background: ${(props) => props.color || "#FFFFFF"};
  box-shadow: ${(props) => props.shadow || "0px 6px 8px #F1E4E9"};
  backdrop-filter: blur(10px);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top:20px;
`;

export const ContentCardExp = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: ${(props) => props.padding || "12px"};
width:${(props) => props.width || "100%"};
height: ${(props) => props.height || "350px"};
background: #FFFFFF;
box-shadow: 0px 6px 8px #F1E4E9;
backdrop-filter: blur(10px);
border-radius: 8px;
flex: none;
order: 0;
flex-grow: 0;
//margin-left: 15px; 
margin-top:20px;
@media (max-width: 768px) {

}
`;

export const ImgProduc = styled.img`
`;

export const SubContentColor = styled.span`
  color: ${(props) => props.color || "#F64282"};
`;

export const Button2 = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 28px;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "48px"};
  background: ${(props) => (props.secondary ? "#F4EFFC" : "#F64282")};
  border-radius: ${(props) => props.borderadius || "16px"};
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => (props.secondary ? "#6200EA" : "#ffffff")};
  border: none;
  margin: ${(props) => props.margin || ""};
  ${(props) => {
    if (props.disabled) {
      return `
        background: #C2C2C2;
      `
    }
  }}
  @media (max-width: 834px) {
    width: 100%;
    margin: ${(props) => props.mmargin || ""};
  }
`;

export const ImgGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  height: 398px;
`;

export const GridContent = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
`;

export const CarrouselContainer = styled.div`
  max-width: 380px;
  height: 800px;
  width: 100%;
  @media (max-width: 1280px) {
    width: 100%;
    margin: 40px 0;
    align-self: center;
  }
`;

export const Container2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 834px) {
    max-width: ${(props) => props.maxWidthTablet};
  }
`;

export const AIInfoContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #403a50;
  margin-bottom: ${(props) => props.mb || "36px"};
  @media (max-width: 834px) {
    font-size: 16px;
    font-weight: 500;
    font-size: 16px;
  }
`;

export const LineContent = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  margin: ${(props) => props.margin || "0"};
  @media (max-width: 834px) {
    font-size: ${(props) => props.msize || "20px"};
    font-weight: ${(props) => props.msize || "500"};
    align-items: center;
    flex-direction: ${(props) => props.mdirection || "row"};
    margin: ${(props) => props.mmargin || ""};
  }
`;

export const ScalesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || "space-between"} ;
  max-width: 1062px;
  width: 100%;
  gap: ${(props) => props.gap || "20px"};
  @media (max-width: 834px) {
    flex-direction: column;
    gap: 44x;
  }
`;

export const ScalesContentCont = styled.div`
  display: flex;
  gap: 18px;
`;

export const DecoSideBar = styled.span`
  width: 8px;
  height: auto;
  background: #EEEEEE;
  border-radius: 8px;
  min-width: 8px;
  max-width: 8px;
  @media (max-width: 834px) {
    display:  ${(props) => props.displayM};
  }
  @media (min-width: 833px) {
    display:  ${(props) => props.display};
  }

`;

export const ScaleInfoCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 302px;
  @media (max-width: 834px) {
    max-width: 100%;
  }
`;

export const HoverImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
`;

export const HoverImg = styled.img``;

export const BurguerMenu = styled.img`
  @media (max-width: 834px) {
    width: 34px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Menu = styled.div`
  max-width: 259px;
  width: 100%;
  min-height: 200px;
  height: auto;
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 5;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(36, 30, 81, 0.15);
  border-radius: 8px 0px 0px 8px;
  padding: 40px 0px 40px 24px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  animation-name: anim2;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  @keyframes anim2 {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }
`;

export const MenuOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.jcontent || "flex-end"};
  align-items: center;
  padding: 8px 0px 8px 8px;
  gap: 12px;
  width: 100%;
  height: 52px;
  font-family: ${(props) => props.fontFamily || "Inter"};
  font-style: normal;
  font-weight: ${(props) => props.weight || "600"};
  color: #403a50;
  font-size: 18px;
  img{
    max-width:60px;
    height:auto;
  }
  &:hover {
    animation-name: anim3;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    cursor: pointer;
  }

  @keyframes anim3 {
    from {
    }
    to {
      background: #f9f9f9;
      border-radius: 16px 0px 0px 16px;
    }
  }
`;

export const CarouselContcontainer = styled.div`
  display: inherit;
  flex-direction: inherit;
`;

export const Menu2 = styled.div`
  max-width: fit-content;
  box-sizing: border-box;
  width: fit-content;
  height: auto;
  position: absolute;
  z-index: 5;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(36, 30, 81, 0.15);
  border-radius: 8px;
  padding: 20px 20px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  animation-name: anim2;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  align-items: flex-start;

  @media (max-width: 834px) {
    right: 78px;
  }
  @media (min-width: 1280px) {
    right: 265px;
  }

  @keyframes anim2 {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Video = styled.div`
  z-index: 5;
  right: 0;
  @media (max-width: 1279px) {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const CheckList = styled.img`
  width: 16px;
  height: 19px;
  margin-right: 37.14px;
`;

export const TextBase = styled.p`
  font-style: normal;
  line-height: 110%;
  font-weight: ${(props) => props.fontWeight || ""};
  font-size: ${(props) => props.fontSize || ""};
  padding: ${(props) => props.padding || ""};
  margin: ${(props) => props.margin || ""};
  color: ${(props) => props.color || ""};
  text-align: ${(props) => props.textAlign || ""};
  width: ${(props) => props.width || ""};
  display: ${(props) => props.display || ""}; 
  line-height: 130%;
  letter-spacing: 1px;

`;

export const NavLinkItem = styled.a`
  cursor: pointer;
  font-size: 16px;
`;

export const WhatsappButton = styled.div`
  cursor: pointer;
  width: 60px;
  height: 60px;
  z-index: 3;
  position: fixed;
  bottom: 70px;
  right: 32px;
  background: #63dd19;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WhatsappImg = styled.img`
  position: absolute;
  left: 18.82%;
  right: 14.12%;
  top: 14.12%;
  width: 40px;
  height: 40px;
`;

export const StyledDemoContainer = styled.div`
padding:80px 0;
display:flex;
justify-content:space-between;
  @media (max-width: 768px) {
    flex-direction:column;
  }

`;

export const DemoTitleContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
height:100%;

img{
  max-width:485px;
}
`;

export const DemoFormContainer = styled.div`
display:flex;
flex-direction:column;
width:40%;
justify-content: space-around;
h4{
  color:#F64282;
  font-family:cabin;
  margin-bottom:0;
}
form{
  display:flex;
  flex-direction:column;
}

  @media (max-width: 768px) {
    width:100%;
    margin: 40px 0;

    button {
      margin-top:30px;
    }

    h4{
      margin-bottom:40px;
    }
  }
`;

export const LpFirstSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 150px;

  @media screen and (max-width: 1440px) {
    gap: 96px;
  }

  @media screen and (max-width: 834px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const LpContainerImageDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 8px;
  background-color: #F9F9F9;
  margin: 0 auto;
`;

export const LpTextContainerImageDescription = styled.div`
  color: #464646;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: 1px;
`;