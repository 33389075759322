import React from "react";
import "./Slider.css";

const Slider = ({ images, width, height, reverse = false, velocity }) => {
  const quantity = images.length;

  return (
    <div
      className="slider"
      style={{
        "--width": `${width}px`,
        "--height": `${height}px`,
        "--quantity": quantity,
        "--velocity": `${velocity}s`,
      }}
      reverse={reverse.toString()}
    >
      <div className="list">
        {images.map((src, index) => (
          <div
            className="item"
            style={{ "--position": index + 1 }}
            key={`slider-item-${index}`}
          >
            <img src={src} alt={`slider-image-${index}`} draggable={false} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
