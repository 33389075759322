import styled, { keyframes } from 'styled-components';

const flip = keyframes`
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(-82deg);
  }
  100% {
    transform: rotateY(0);
  }
`;

export const SpanText = styled.span`
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  line-height: 75px;
  letter-spacing: -1.95px;
  width: 100%;
  font-style: italic;  
  display: inline-block;
  text-rendering: geometricPrecision;

  span {
    display: inline-block;

      padding-right: 9px;
      margin-right: -8px;
      color: transparent;

  }
  span:nth-child(1) {
    background: linear-gradient(-15deg, #F64282 -50%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
     
  }
  span:nth-child(2) {
    background: linear-gradient(-35deg, #F64282 -50%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
  }
  span:nth-child(3) {
    background: linear-gradient(-20deg, #F64282 -25%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;

  }
  span:nth-child(4) {
    background: linear-gradient(-30deg, #F64282 -15%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text; 
    }
  span:nth-child(5) {
    background: linear-gradient(-35deg, #F64282 -15%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
  }
  span:nth-child(6) {
    background: linear-gradient(-60deg, #F64282 5%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
  }
  span:nth-child(7) {
    background: linear-gradient(-50deg, #F64282 5%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
  }

  span:nth-child(8) {
    background: linear-gradient(-55deg, #F64282 5%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
  }

  span:nth-child(9) {
    background: linear-gradient(-60deg, #F64282 5%, #006DFF 100%);
      -webkit-background-clip: text;
      background-clip: text;
  }

  span.in {
    animation: ${flip} 0.3s forwards; /* Duración total de la animación */
  }

  /* Añade más reglas para más spans si es necesario */
  @media only screen and (max-width: 450px) {
    font-size: 50px;
    line-height: 65px;

  }
`;
