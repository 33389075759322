import React from "react";
import {
  Container,
  Row,
  Title,
  SpanText,
  TitleCardMain,
  IconCardMain,
  ContainerBackground,
  ContainerCards,
  Card,
  TitleCard,
  TextCard,
  CardMain,
} from "./styles";
import c1 from "../../Assets/Imgs/c1.png";
import c2 from "../../Assets/Imgs/c2.png";
import c3 from "../../Assets/Imgs/c3.png";
import c4 from "../../Assets/Imgs/c4.png";
import ic1 from "../../Assets/Imgs/ic1.svg";
import ic2 from "../../Assets/Imgs/ic2.svg";
import ic3 from "../../Assets/Imgs/ic3.svg";
import ic4 from "../../Assets/Imgs/ic4.svg";
import ic12 from "../../Assets/Imgs/ic1-2.svg";
import ic22 from "../../Assets/Imgs/ic2-2.svg";
import ic32 from "../../Assets/Imgs/ic3-2.svg";

// Componente ThirdSection
const ThirdSection = ({
  nos_flow_conv,
  nos_flow_conv_desc,
  nos_flow_cent,
  nos_flow_cent_desc,
  nos_flow_ia,
  nos_flow_ia_desc,
  nos_flow_per,
  nos_flow_per_desc,
  esc_tit,
  esc_tit2,
  esc_sat,
  esc_sat_desc,
  esc_disp,
  esc_disp_desc,
  esc_usr,
  esc_usr_desc,
}) => {
  return (
    <Container>
      <Row>
        {/* Tarjeta principal con imagen de fondo y contenido */}
        <CardMain backgroundImage={c1}>
          <IconCardMain src={ic1} draggable={false} />
          <TitleCardMain>{nos_flow_conv}</TitleCardMain>
          <TextCard>
            {nos_flow_conv_desc}
          </TextCard>
        </CardMain>
        <CardMain backgroundImage={c2}>
          <IconCardMain src={ic2} draggable={false} />
          <TitleCardMain>{nos_flow_cent}</TitleCardMain>
          <TextCard>
            {nos_flow_cent_desc}
          </TextCard>
        </CardMain>
        <CardMain backgroundImage={c3}>
          <IconCardMain src={ic3} draggable={false} />
          <TitleCardMain>{nos_flow_ia}</TitleCardMain>
          <TextCard>
            {nos_flow_ia_desc}
          </TextCard>
        </CardMain>
        <CardMain backgroundImage={c4}>
          <IconCardMain src={ic4} draggable={false} />
          <TitleCardMain>{nos_flow_per}</TitleCardMain>
          <TextCard>
            {nos_flow_per_desc}
          </TextCard>
        </CardMain>
      </Row>
      <ContainerBackground>
        {/* Título de la sección con estilo centrado */}
        <Title style={{ textAlign: "center" }}>
          {esc_tit}
          <SpanText> {esc_tit2}</SpanText>
        </Title>
        <ContainerCards>
          {/* Tarjeta con icono, título y texto */}
          <Card>
            <IconCardMain src={ic12} draggable={false} />
            <TitleCard>{esc_sat}</TitleCard>
            <TextCard>
              {esc_sat_desc}
            </TextCard>
          </Card>

          <Card>
            <IconCardMain src={ic22} draggable={false} />
            <TitleCard>{esc_disp}</TitleCard>
            <TextCard>
              {esc_disp_desc}
            </TextCard>
          </Card>
          <Card>
            <IconCardMain src={ic32} draggable={false} />
            <TitleCard>{esc_usr}</TitleCard>
            <TextCard>
              {esc_usr_desc}
            </TextCard>
          </Card>
        </ContainerCards>
      </ContainerBackground>
    </Container>
  );
};

export default ThirdSection;