import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  background: #ffffff;
  padding-top: 130px;
  /* padding-bottom: 100px; */
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media only screen and (max-width: 1200px) {
    /* padding-top: 0px; */
  }

  @media only screen and (max-width: 450px) {
    padding-top: 50px;
    padding-bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 100px;
  justify-content: center;
  width: 100%;
  padding: 0 40px;
  @media only screen and (max-width: 1200px) {
    gap: 20px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  @media only screen and (max-width: 450px) {
    padding: 0 24px;
  }
`;

export const LeftContent = styled.div`
  /* width: 50%; */
  /* flex-grow: 1; */
  max-width: 520px;
  padding: 24px;
`;

export const RightContent = styled.div`
  /* width: 50%; */
  display: flex;
  justify-content: center;
  /* flex-grow: 1; */
  @media only screen and (max-width: 768px) {
       width: 100%;
    }
`;

export const ImageDiv = styled.div`
  position: relative;
  width: 90%;

  @media only screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 550px;
`

export const Subtitle = styled.p`
  font-family: "Space Grotesk", sans-serif;

  margin: 0;
  color: #000000;
  font-size: 48px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -1.44px;
  margin-bottom: 31px;

  @media only screen and (max-width: 450px) {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
`;

export const SpanText = styled.span`
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -1.44px;
  font-style: italic;

  background: linear-gradient(317.36deg, #F64282 19.95%, #006DFF 76.03%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

padding-right: 10px;
@media only screen and (max-width: 450px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Text = styled.p`
  margin: 0;
  margin-bottom: 36px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #696969;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 25px 15px 20px;
  border-radius: 15px;
  background: #181837;

  margin-bottom: 0;

  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageChip = styled.img`
  width: 30px;
  height: 30px;
`;

export const TextChip = styled.p`
  margin: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.6px;
  font-family: "Space Grotesk", sans-serif;
`;

const upDownAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); /* Ajusta este valor según sea necesario */
  }
`;

const rightLeftAnimation = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px); /* Ajusta este valor según sea necesario */
  }
`;


export const SquareGray = styled.div`
  width: 640px;
  height: 540px;
  border-radius: 25px;
  background-color: #efefef;
  animation: ${upDownAnimation} 6s infinite; /* Ajusta la duración según sea necesario */

  @media only screen and (max-width: 768px) {
    width: calc(640px / 2);
    height: calc(540px / 2);

  }
`;

export const CircleGray = styled.div`
  background-color: #efefef;
  width: 355px;
  height: 355px;
  border: 5px solid #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: -50px;
  animation: ${rightLeftAnimation} 6s infinite;
  @media only screen and (max-width: 768px) {
    width: calc(355px / 2);
    height: calc(355px / 2);
    bottom: -25px;
    left: -25px;
  }
`;

export const PurpleContainer = styled.div`
  padding: 0 24px;
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
`;

export const PurpleWrapper = styled.div`
  border-radius: 30px;
  background: linear-gradient(317.36deg, #F64282 19.95%, #006DFF 76.03%);

  padding: 80px 80px 68px 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 12;

  margin-top: 150px;
  max-width: 1290px;
  width: 100%;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 50px;
        padding: 50px 24px;
    }

    @media only screen and (max-width: 450px) {
      /* padding: 0px 24px; */
      margin-top: 100px;
    }
`;

export const TextInPurple = styled.p`
  margin: 0;
  color: #fff;
  line-height: 57px;
  letter-spacing: -1.44px;
  font-size: 48px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;

    @media only screen and (max-width: 768px) {
        text-align: center;
    }

    @media only screen and (max-width: 450px) {
      font-size: 38px;
      line-height: 40px;
    }
`;

export const SpanTextInPurple = styled.span`
  margin: 0;
  color: #fff;
  line-height: 57px;
  letter-spacing: -1.44px;
  font-size: 48px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-style: italic;

  @media only screen and (max-width: 768px) {
        text-align: center;
    }

    @media only screen and (max-width: 450px) {
      font-size: 38px;
      line-height: 40px;
    }
`;

export const ButtonInPurple = styled.button`
  color: #ffffff;
  background-color: transparent;
  border: 2px solid #fff;
  /* padding: 19px 29px; */
  text-transform: capitalize;
  border-radius: 14px;
  font-weight: 700;
  font-size: 16px;
  line-height: 11px;
  /* width: max-content; */
  /* min-width: 500px; */
  width: 40%;
  height: 51px;
  gap: 7px;
  position: relative;
  cursor: pointer;  
  z-index: 10;
  &:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #fff;
  }

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ellipse1 = keyframes`
0% {
    transform: translate(-50%, 55%) scale(0.8);
    opacity: 0;
}
20% {
    transform: translate(-50%, 55%) scale(0.9);
    opacity: 1;
}
100% {
    transform: translate(-50%, 55%) scale(1);
    opacity: 0;
}
`;

export const Elipse1 = styled.div`
  width: 200px;
  height: 200px;
  animation: ${ellipse1} 3s linear 0s infinite;

  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;
export const Elipse2 = styled.div`
  width: 290px;
  height: 290px;
  animation: ${ellipse1} 3s linear 0s infinite;
  animation-delay: 0.5s;

  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;
export const Elipse3 = styled.div`
  width: 394px;
  height: 394px;
  animation: ${ellipse1} 3s linear 0s infinite;
  animation-delay: 1s;
  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;
export const Elipse4 = styled.div`
  width: 496px;
  height: 496px;
  animation: ${ellipse1} 3s linear 0s infinite;
  animation-delay: 1.5s;

  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;


export const BottomBackground = styled.div`
  background-color: #181837;
  position: absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
`