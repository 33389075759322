import { useState, useEffect } from 'react';

// Hook personalizado para fijar un elemento basado en el scroll
const useFixedOnScroll = (threshold = 50) => {
  // Estado para determinar si el elemento está fijado o no
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    // Función que maneja el evento de scroll
    const handleScroll = () => {
      // Si el scroll vertical es mayor que el umbral, fijar el elemento
      if (window.scrollY > threshold) {
        setIsFixed(true);
      } else {
        // Si el scroll vertical es menor o igual que el umbral, no fijar el elemento
        setIsFixed(false);
      }
    };

    // Agregar el evento de scroll al montar el componente
    window.addEventListener('scroll', handleScroll);
    // Limpiar el evento de scroll al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [threshold]); // El efecto depende del umbral

  // Retornar el estado que indica si el elemento está fijado o no
  return isFixed;
};

export default useFixedOnScroll;